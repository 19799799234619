import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import CoverLayout from "layouts/authentication/components/BasicLayout";
import rgba from "assets/theme/functions/rgba";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import { IoMdCheckmarkCircle } from "react-icons/io";
import RcSlider from 'rc-slider'; // Correct import statement
import 'rc-slider/assets/index.css'; // Import the default styles
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useMediaQuery } from '@mui/material'; // Import useMediaQuery
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // carousel styles
import bgDash4 from "assets/images/bg4.svg"; // Replace with your background image
import longlogoblack from "assets/images/codegrowlong-black.png"; // Replace with your background image
import { FaArrowRightLong } from "react-icons/fa6";
import box2 from "assets/images/box2.png"; // Replace with your background imageminicolourbg.svg
import minicolourbg from "assets/images/minicolourbg.svg"; // Replace with your background image
import redbox1 from "assets/images/redbox1.png"; // Replace with your background image
import redbox2 from "assets/images/redbox2.png"; // Replace with your background image
import ai1 from "assets/images/ai1.png"; // Replace with your background image
import ai2 from "assets/images/ai2.png"; // Replace with your background image
import ai3 from "assets/images/ai3.png"; // Replace with your background image
import macbook from "assets/images/macbook.png"; // Replace with your background image
import monthly from "assets/images/monthly.svg"; // Replace with your background image
import yearly from "assets/images/yearly.svg"; // Replace with your background image
import { fontGrid } from "@mui/material/styles/cssUtils";

function SignIn() {
  const navigate = useNavigate();
  const [appsPerMonth, setAppsPerMonth] = useState(2);
  const [dailyRevenue, setDailyRevenue] = useState(10);
  const [flippaSalePrice, setFlippaSalePrice] = useState(1500);
  const monthlyRevenuePerApp = dailyRevenue * 30; // 30 days in a month
  const yearlyRevenuePerApp = monthlyRevenuePerApp * 12;
  const totalRevenuePerMonth = (appsPerMonth * 12) * monthlyRevenuePerApp;
  const totalRevenuePerYear = totalRevenuePerMonth * 12;
  const totalFlippaRevenue = (appsPerMonth * 12) * flippaSalePrice;
  const totalCombinedRevenue = totalRevenuePerYear + totalFlippaRevenue;
  const totalCombinedMonthly = totalCombinedRevenue / 12;
  const isMobile = useMediaQuery('(max-width:600px)'); // Adjust breakpoint as needed
  const [openQuestion, setOpenQuestion] = useState(null);
  const [isYearly, setIsYearly] = useState(true);

  const toggleQuestion = (index) => {
    setOpenQuestion(openQuestion === index ? null : index);
  };

  const faqData = [
    {
      question: "What is included in the CodeGrow subscription?",
      answer:
        "The subscription includes unlimited access to our AI-powered app development tools, fully customizable app templates, video tutorials, pre-built Firebase and AdMob integrations, and more."
    },
    {
      question: "What AI features are included?",
      answer:
        "CodeGrow's AI tools can generate app designs, suggest code improvements, debug issues, and even create fully functional components to save you time and effort."
    },
    {
      question: "How many apps can I create with CodeGrow?",
      answer:
        "You can create an unlimited number of apps with your subscription. There's no cap on creativity or output!"
    },
    {
      question: "What programming technologies are used in these apps?",
      answer:
        "CodeGrow apps are built with Flutter for cross-platform compatibility and leverage Firebase for back-end services. SQLite is also available for local storage."
    },
    {
      question: "Are there templates available for specific types of apps?",
      answer:
        "Yes, CodeGrow provides a variety of templates for apps such as games, productivity tools, social media integrations, and more. All templates are fully customizable."
    },
    {
      question: "Can I access CodeGrow on multiple devices?",
      answer:
        "Absolutely! CodeGrow is fully responsive and works seamlessly on desktops, tablets, and smartphones, allowing you to build apps from anywhere."
    },
    {
      question: "How do I get started with CodeGrow?",
      answer:
        "Once you sign up, you'll receive immediate access to the platform. Start by exploring the tutorials and templates, and you'll be creating your first app in no time."
    },
    {
      question: "Can I cancel my subscription at any time?",
      answer:
        "Yes, you can cancel your subscription anytime by logging into your account and navigating to the billing section."
    },
    {
      question: "Does CodeGrow offer real-time support?",
      answer:
        "Yes, our support team is available to assist you with any questions or issues you may encounter while using CodeGrow."
    }
  ];


  function navigateToHome() {

    // Navigation logic
    navigate("/authentication/home");
  }

  function navigateToSignIn() {
    // TikTok PII Identification (if applicable)
    ttq.identify({
      "email": "<hashed_email_address>", // Replace with SHA-256 hashed email
      "phone_number": "<hashed_phone_number>", // Replace with SHA-256 hashed phone number
      "external_id": "<hashed_external_id>" // Replace with SHA-256 hashed external ID
    });

    // TikTok Event Tracking
    ttq.track('ViewContent', {
      page_section: "Sign-In Page"
    });

    // Navigation logic
    navigate("/authentication/sign-in");
  }

  return (
    <><CoverLayout >

      {/* top section start */}
      <VuiBox
        style={{
          width: '100vw',
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
          backgroundColor: "white",
          minHeight: "100vh",
          marginBottom: '-20px',
          backgroundImage: `url(${bgDash4})`, // Background image for the landing page
          backgroundSize: "cover", // Ensures the image covers the entire area
          backgroundPosition: "center", // Centers the image
          backgroundRepeat: "no-repeat", // Prevents the image from repeating
          padding: "0", // Remove padding to avoid affecting width
        }}
      >

        {/* top menu*/}
        <VuiBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width={isMobile ? "100%" : "90%"}
          // maxWidth="800px"
          marginTop="0px"
          padding="20px"

          borderRadius={borders.borderRadius.md}
        >
          {/* Logo on the Left */}
          <VuiBox display="flex" alignItems="center">
            <img
              src={longlogoblack} // Remove the `${}`
              alt="Logo"
              style={{ height: isMobile ? "17vw" : "10vw", marginBottom: '-20px' }}
            />

          </VuiBox>

          {/* Buttons on the Right */}
          <VuiBox display="flex" gap="20px" style={{ marginBottom: '-20px' }}
          >
            <VuiButton
              variant="contained"
              color="primary"
              size="medium"
              onClick={() => navigateToSignIn()}
              style={{
                backgroundColor: "transparent",
                color: "#fff",
                fontSize: isMobile ? "3.5vw" : "1.5vw",
                padding: "10px 20px",
                borderRadius: '55px'
              }}
            >
              Log in
            </VuiButton>

            <VuiButton
              variant="contained"
              color="primary"
              size="medium"
              onClick={() => navigateToSignIn()}
              style={{
                backgroundColor: "#404040",
                color: "#fff",
                fontSize: isMobile ? "3.5vw" : "1.5vw",
                padding: "10px 20px",
                borderRadius: '55px',
                display: isMobile ? "none" : "block",
              }}
            >
              Get Started
            </VuiButton>
            <VuiButton
              variant="contained"
              color="primary"
              size="medium"
              onClick={navigateToHome}
              style={{
                backgroundColor: "white",
                color: "#fe3737",
                fontSize: isMobile ? "3.5vw" : "1.5vw",
                padding: "10px 20px",
                borderRadius: '55px'
              }}
            >
              See Demo
            </VuiButton>

          </VuiBox>
        </VuiBox>
        {/* top menu end */}

        {/* triple section menu*/}
        <VuiBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"

          style={{
            width: isMobile ? "90%" : "60%"
          }}
          // maxWidth="800px"
          marginTop="0px"
          padding="20px"
          background={rgba(palette.info.main, 0.8)}
          borderRadius={borders.borderRadius.md}
          flexDirection={isMobile ? "row" : "row"}
        >


          <VuiBox
            component="img"
            src="https://images.crunchbase.com/image/upload/c_pad,f_auto,q_auto:eco,dpr_1/v1455748408/tr0tg0vvkketzrvfymcw.png"
            alt="Source Code Ready"
            sx={{
              width: isMobile ? "25vw" : "20vw", // Adjust the size based on the device
              height: isMobile ? "25vw" : "20vw", // Make it a square
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "30px",
              borderRadius: "4vw", // Optional: Add rounded corners
            }}
          />
          <VuiTypography
            style={{
              fontSize: isMobile ? "7.5vw" : "5.5vw", // Adjusts according to the viewport width
              fontWeight: "bold",
              color: "white",
              marginBottom: isMobile ? "20px" : "-20px",
              textAlign: "center", // Centers the text
            }}
          >
            VS
          </VuiTypography>

          <VuiBox
            component="img"
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_AyTunqeJbq0KCybzguwhYpmXNhpI9RSaAHs-2Bznc3f14hqQ_KsUjuDaYtJ08Pzy3Eg&usqp=CAU"
            alt="Source Code Ready"
            sx={{
              width: isMobile ? "25vw" : "20vw", // Adjust the size based on the device
              height: isMobile ? "25vw" : "20vw", // Make it a square
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "30px",
              borderRadius: "4vw", // Optional: Add rounded corners
            }}
          />




        </VuiBox>
        {/* triple section end */}


        <VuiBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          width={isMobile ? "100%" : "80%"}
          // maxWidth="800px"
          marginTop="0px"
          padding="20px"


          borderRadius={borders.borderRadius.md}
        >




          <VuiTypography
            style={{
              component: "h1",
              fontSize: isMobile ? "7.5vw" : "6.5vw", // Adjusts according to the viewport width
              fontWeight: "bold",
              color: "white",
              marginBottom: isMobile ? "0px" : "-10px",
              textAlign: "center", // Centers the text
            }}
          >
            BuildFire vs AppMakr
          </VuiTypography>
          <VuiTypography
            style={{
              component: "h2",
              fontSize: isMobile ? "7.5vw" : "3.5vw", // Adjusts according to the viewport width
              fontWeight: "bold",
              color: "#fe3737",
              marginBottom: "15px",
              textAlign: "center", // Centers the text
            }}
          >
            Which No-Code Platform is Right for Your Mobile App?
          </VuiTypography>

          <VuiBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            width={isMobile ? "100%" : "85%"}
            // maxWidth="800px"
            marginTop="0px"
            padding="20px"
            marginBottom="100px"
            borderRadius={borders.borderRadius.md}
          >

            <VuiTypography style={{ component: "description", textAlign: "center", fontSize: isMobile ? "4.5vw" : "1.5vw", fontWeight: "bold", color: "white", marginBottom: "20px" }}>
              As two of the most popular no-code platforms, BuildFire and AppMakr empower users to create mobile apps without writing a single line of code. Whether you’re a business owner looking for a cost-effective solution or a developer seeking to streamline your workflows, these tools offer distinct advantages. In this comparison, we’ll explore their user-friendliness, supported platforms, integrations, and learning curve to help you choose the best no-code app builder for your next project.

            </VuiTypography>
          </VuiBox>



        </VuiBox>

      </VuiBox>
      {/* top section end */}

      {/* second section start */}
      <VuiBox
        style={{
          width: '100vw',
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
          background: "white", // Fading effect

          marginTop: '0px',

          padding: "0", // Remove padding to avoid affecting width
        }}
      >


        {/* top menu*/}
        <VuiBox
          display="flex"
          justifyContent="space-between"
          flexDirection={isMobile ? "column" : "row"}
          alignItems={isMobile ? "start" : "center"}
          width={isMobile ? "100%" : "90%"}
          // maxWidth="800px"
          marginTop="-70px"
          padding="20px"
          style={{
            background: "linear-gradient(to top, white 60%, transparent 100%)", // Fading effect
          }}
          borderRadius={borders.borderRadius.md}
        >
          <VuiBox

            width={isMobile ? "100%" : "48%"}
          >
            <VuiTypography style={{ textAlign: "start", fontSize: isMobile ? "4.5vw" : "2.8vw", fontWeight: "bold", color: "black", marginBottom: "20px" }}>
              AI-Driven Tools <bold style={{ color: '#69696a' }}> Powering App Store Success</bold>
            </VuiTypography>
          </VuiBox>

          {/* Buttons on the Right */}
          <VuiBox display="flex" gap="20px" style={{ marginBottom: '-20px' }}
          >

            <VuiButton
              variant="contained"
              color="primary"
              size="medium"
              onClick={() => navigateToSignIn()}
              style={{
                backgroundColor: 'transparent',
                border: " 2px solid #1079fb",
                color: " #1079fb",
                fontSize: isMobile ? "3.5vw" : "1.5vw",
                padding: "10px 20px",
                borderRadius: '55px'
              }}
            >
              Get Started
              <FaArrowRightLong
                style={{ marginLeft: "8px" }}

              />  </VuiButton>

          </VuiBox>
        </VuiBox>
        {/* top menu end */}
      </VuiBox>

      {/* second section end */}

      {/* third section start */}
      <VuiBox
        style={{
          width: '100vw',
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
          background: "linear-gradient(to top, #F7F8FB 95%, white 100%)", // Fading effect
          minHeight: "100vh",
          marginTop: '0px',

          padding: "0", // Remove padding to avoid affecting width
        }}
      >



        {/* section one start */}
        <VuiBox
          display="flex"
          justifyContent="space-between"
          flexDirection={isMobile ? "column" : "column"}
          // alignItems="start"
          width={isMobile ? "100%" : "90%"}
          marginTop="5%"
          padding="40px"
          style={{
            background: "white",
          }}
          borderRadius="25px"
        >
          <VuiTypography
            style={{
              component: "h1",
              textAlign: "center",
              fontSize: isMobile ? "6.5vw" : "3.2vw",
              fontWeight: "bold",
              color: "#fe3737",
              marginBottom: "50px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            A Quick Overview of BuildFire and AppMakr

          </VuiTypography>

          {/* what is bubble start */}
          <VuiTypography
            style={{
              component: "h1",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "2vw",
              fontWeight: "bold",
              color: "#69696a",
              marginBottom: "10px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            <bold style={{ color: "black" }}>What is BuildFire?</bold>
          </VuiTypography>

          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              // fontWeight: "bold",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            BuildFire is a powerful no-code platform designed for creating custom mobile applications for iOS and Android. Known for its flexibility and scalability, BuildFire is a popular choice for business apps, employee portals, and customer engagement tools.

          </VuiTypography>

          <br />

          <VuiTypography
            style={{
              component: "h1",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.8vw",
              fontWeight: "bold",
              color: "#69696a",
              marginBottom: "10px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            <bold style={{ color: "black" }}>Key Features</bold>
          </VuiTypography>

          <VuiTypography
            style={{
              component: "h3",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              // fontWeight: "bold",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            <li>

              Drag-and-drop interface for easy customization


            </li>
            <li>

              Extensive plugin marketplace for added functionality
            </li>
            <li>

              Built-in analytics and push notifications
            </li>

          </VuiTypography>
          {/* what is bubble end */}

          <br />

          {/* what is flutterflow start */}
          <VuiTypography
            style={{
              component: "h1",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "2vw",
              fontWeight: "bold",
              color: "#69696a",
              marginBottom: "10px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            <bold style={{ color: "black" }}>What is AppMakr?</bold>
          </VuiTypography>

          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              // fontWeight: "bold",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            AppMakr is an intuitive DIY app builder tailored for small businesses, organizations, and personal use. Its simple interface and focus on affordability make it a great option for building basic mobile apps quickly.

          </VuiTypography>

          <br />

          <VuiTypography
            style={{
              component: "h1",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.8vw",
              fontWeight: "bold",
              color: "#69696a",
              marginBottom: "10px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            <bold style={{ color: "black" }}>Key Features</bold>
          </VuiTypography>

          <VuiTypography
            style={{
              component: "h3",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              // fontWeight: "bold",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            <li>

              Pre-designed templates for fast app creation

            </li>
            <li>

              Integration with Google Maps, social media, and more
            </li>
            <li>

              Real-time app updates and easy publishing
            </li>

          </VuiTypography>
          {/* what is flutterflow end */}

        </VuiBox>
        {/* section one end */}


        {/* section 2 heading start */}
        <VuiBox
          display="flex"
          justifyContent="space-between"
          flexDirection={isMobile ? "column" : "column"}
          // alignItems="start"
          width={isMobile ? "100%" : "90%"}
          marginTop="5%"
          padding="40px"
          style={{
            background: "transparent",
          }}
          borderRadius="25px"
        >
          <VuiTypography
            style={{
              component: "h1",

              textAlign: "center",
              fontSize: isMobile ? "6.5vw" : "3.2vw",
              fontWeight: "bold",
              color: "black",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            Key Comparison Factors

          </VuiTypography>



        </VuiBox>
        {/* section 2 heading end */}

        {/* section 2 start */}
        <VuiBox
          display="flex"
          justifyContent="space-between"
          flexDirection={isMobile ? "column" : "column"}
          // alignItems="start"
          width={isMobile ? "100%" : "90%"}
          marginTop="5%"
          padding="40px"
          style={{
            background: "white",
          }}
          borderRadius="25px"
        >
          <VuiTypography
            style={{
              component: "h1",

              textAlign: "center",
              fontSize: isMobile ? "6.5vw" : "3.2vw",
              fontWeight: "bold",
              color: "#fe3737",
              marginBottom: "50px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            User-Friendliness
          </VuiTypography>

          {/* heading start */}
          <VuiTypography
            style={{
              textAlign: "start",
              component: "h1",

              fontSize: isMobile ? "4.5vw" : "2vw",
              fontWeight: "bold",
              color: "#69696a",
              marginBottom: "20px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            <bold style={{ color: "black" }}>How Easy Are These Tools to Use?</bold>
          </VuiTypography>
          {/* heading end */}

          {/* bubble */}
          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              fontWeight: 'bold',
              color: "#fe3737",
              marginBottom: "10px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            BuildFire



          </VuiTypography>


          <VuiTypography
            style={{
              component: "h3",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            BuildFire offers a user-friendly drag-and-drop interface, but its advanced customization options might require some technical knowledge.
          </VuiTypography>
          {/* bubble end */}

          <br />

          {/* flutter  */}

          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              fontWeight: 'bold',
              color: "#fe3737",
              marginBottom: "10px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            AppMakr


          </VuiTypography>


          <VuiTypography
            style={{
              component: "h3",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            AppMakr’s interface is straightforward and caters to beginners, making it an excellent choice for non-technical users.
          </VuiTypography>
          {/* flutter end */}

          <br />

          {/* winner  */}

          <VuiTypography
            style={{
              component: "h1",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              fontWeight: 'bold',
              color: "#fe3737",
              marginBottom: "10px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            Winner


          </VuiTypography>


          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            AppMakr, for its simplicity and beginner-friendly design.
          </VuiTypography>
          {/* winner end */}


        </VuiBox>
        {/* section 2 end */}

        {/* section 3 start */}
        <VuiBox
          display="flex"
          justifyContent="space-between"
          flexDirection={isMobile ? "column" : "column"}
          // alignItems="start"
          width={isMobile ? "100%" : "90%"}
          marginTop="5%"
          padding="40px"
          style={{
            background: "white",
          }}
          borderRadius="25px"
        >
          <VuiTypography
            style={{
              component: "h1",

              textAlign: "center",
              fontSize: isMobile ? "6.5vw" : "3.2vw",
              fontWeight: "bold",
              color: "#fe3737",
              marginBottom: "50px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            Supported Platforms
          </VuiTypography>

          {/* heading start */}
          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "2vw",
              fontWeight: "bold",
              color: "#69696a",
              marginBottom: "20px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            <bold style={{ color: "black" }}>Where Can You Deploy Your Apps?</bold>
          </VuiTypography>
          {/* heading end */}

          {/* bubble */}
          <VuiTypography
            style={{
              component: "h1",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              fontWeight: 'bold',
              color: "#fe3737",
              marginBottom: "10px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            BuildFire



          </VuiTypography>


          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            Supports both iOS and Android platforms, with robust native app capabilities.


          </VuiTypography>
          {/* bubble end */}

          <br />

          {/* flutter  */}

          <VuiTypography
            style={{
              component: "h1",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              fontWeight: 'bold',
              color: "#fe3737",
              marginBottom: "10px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            AppMakr



          </VuiTypography>


          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            Primarily focuses on creating mobile web apps and native apps for iOS and Android, but its features are more limited compared to BuildFire.
          </VuiTypography>
          {/* flutter end */}

          <br />

          {/* winner  */}

          <VuiTypography
            style={{
              component: "h1",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              fontWeight: 'bold',
              color: "#fe3737",
              marginBottom: "10px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            Winner


          </VuiTypography>


          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            BuildFire, for its advanced native app capabilities.
          </VuiTypography>
          {/* winner end */}


        </VuiBox>
        {/* section 3 end */}

        {/* section 4 start */}
        <VuiBox
          display="flex"
          justifyContent="space-between"
          flexDirection={isMobile ? "column" : "column"}
          // alignItems="start"
          width={isMobile ? "100%" : "90%"}
          marginTop="5%"
          padding="40px"
          style={{
            background: "white",
          }}
          borderRadius="25px"
        >
          <VuiTypography
            style={{
              component: "h1",

              textAlign: "center",
              fontSize: isMobile ? "6.5vw" : "3.2vw",
              fontWeight: "bold",
              color: "#fe3737",
              marginBottom: "50px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            Integrations
          </VuiTypography>

          {/* heading start */}
          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "2vw",
              fontWeight: "bold",
              color: "#69696a",
              marginBottom: "20px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            <bold style={{ color: "black" }}>What Can You Connect to Your App?</bold>
          </VuiTypography>
          {/* heading end */}

          {/* bubble */}
          <VuiTypography
            style={{
              component: "h1",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              fontWeight: 'bold',
              color: "#fe3737",
              marginBottom: "10px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            BuildFire


          </VuiTypography>


          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            Provides a vast plugin marketplace with integrations for e-commerce, CRM systems, and social media.

          </VuiTypography>
          {/* bubble end */}

          <br />

          {/* flutter  */}

          <VuiTypography
            style={{
              component: "h1",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              fontWeight: 'bold',
              color: "#fe3737",
              marginBottom: "10px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            AppMakr


          </VuiTypography>


          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            Offers basic integrations, including Google Maps, RSS feeds, and social media platforms, but lacks the depth of BuildFire’s options.


          </VuiTypography>
          {/* flutter end */}

          <br />

          {/* winner  */}

          <VuiTypography
            style={{
              component: "h1",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              fontWeight: 'bold',
              color: "#fe3737",
              marginBottom: "10px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            Winner


          </VuiTypography>


          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            BuildFire, for its extensive integration options.
          </VuiTypography>
          {/* winner end */}


        </VuiBox>
        {/* section 4 end */}


        {/* section 5 start */}
        <VuiBox
          display="flex"
          justifyContent="space-between"
          flexDirection={isMobile ? "column" : "column"}
          // alignItems="start"
          width={isMobile ? "100%" : "90%"}
          marginTop="5%"
          padding="40px"
          style={{
            background: "white",
          }}
          borderRadius="25px"
        >
          <VuiTypography
            style={{
              component: "h1",

              textAlign: "center",
              fontSize: isMobile ? "6.5vw" : "3.2vw",
              fontWeight: "bold",
              color: "#fe3737",
              marginBottom: "50px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            Learning Curve
          </VuiTypography>

          {/* heading start */}
          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "2vw",
              fontWeight: "bold",
              color: "#69696a",
              marginBottom: "20px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            <bold style={{ color: "black" }}>Which Tool is Faster to Master?</bold>
          </VuiTypography>
          {/* heading end */}

          {/* bubble */}
          <VuiTypography
            style={{
              component: "h1",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              fontWeight: 'bold',
              color: "#fe3737",
              marginBottom: "10px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            BuildFire


          </VuiTypography>


          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            While BuildFire is relatively easy to use, its advanced features may take time to learn for non-technical users.
          </VuiTypography>
          {/* bubble end */}

          <br />

          {/* flutter  */}

          <VuiTypography
            style={{
              component: "h1",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              fontWeight: 'bold',
              color: "#fe3737",
              marginBottom: "10px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            AppMakr

          </VuiTypography>


          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            Designed with simplicity in mind, AppMakr has a much shorter learning curve, making it ideal for first-time app builders.

          </VuiTypography>
          {/* flutter end */}

          <br />

          {/* winner  */}

          <VuiTypography
            style={{
              component: "h1",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              fontWeight: 'bold',
              color: "#fe3737",
              marginBottom: "10px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            Winner


          </VuiTypography>


          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            AppMakr, for its ease of use and minimal learning curve.
          </VuiTypography>
          {/* winner end */}


        </VuiBox>
        {/* section 5 end */}


        {/* section 6 heading start */}
        <VuiBox
          display="flex"
          justifyContent="space-between"
          flexDirection={isMobile ? "column" : "column"}
          // alignItems="start"
          width={isMobile ? "100%" : "90%"}
          marginTop="5%"
          padding="40px"
          style={{
            background: "transparent",
          }}
          borderRadius="25px"
        >
          <VuiTypography
            style={{
              component: "h1",

              textAlign: "center",
              fontSize: isMobile ? "6.5vw" : "3.2vw",
              fontWeight: "bold",
              color: "black",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            Pros and Cons

          </VuiTypography>



        </VuiBox>
        {/* section 6 heading end */}

        {/* section 6 start */}
        <VuiBox
          display="flex"
          justifyContent="space-between"
          flexDirection={isMobile ? "column" : "column"}
          // alignItems="start"
          width={isMobile ? "100%" : "90%"}
          marginTop="5%"
          padding="40px"
          style={{
            background: "white",
          }}
          borderRadius="25px"
        >


          {/* heading start */}
          <VuiTypography
            style={{
              component: "h1",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "2vw",
              fontWeight: "bold",
              color: "#008000",
              marginBottom: "20px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            BuildFire Pros
          </VuiTypography>
          {/* heading end */}



          <VuiTypography
            style={{
              component: "h3",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            <li>
              Highly customizable with extensive plugins

            </li>
            <li>
              Excellent for creating scalable, business-oriented apps
            </li>
            <li>
              Strong analytics and push notification features
            </li>
          </VuiTypography>


          <br />



          {/* heading start */}
          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "2vw",
              fontWeight: "bold",
              color: "#fe3737",
              marginBottom: "20px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            BuildFire Cons
          </VuiTypography>
          {/* heading end */}



          <VuiTypography
            style={{
              component: "h3",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            <li>
              Slightly higher learning curve for advanced features
            </li>
            <li>
              More expensive compared to AppMakr
            </li>

          </VuiTypography>

          {/* bubble end */}




        </VuiBox>
        {/* section 6 end */}


        {/* section 7 start */}
        <VuiBox
          display="flex"
          justifyContent="space-between"
          flexDirection={isMobile ? "column" : "column"}
          // alignItems="start"
          width={isMobile ? "100%" : "90%"}
          marginTop="5%"
          padding="40px"
          style={{
            background: "white",
          }}
          borderRadius="25px"
        >


          {/* heading start */}
          <VuiTypography
            style={{
              component: "h1",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "2vw",
              fontWeight: "bold",
              color: "#008000",
              marginBottom: "20px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            AppMakr Pros
          </VuiTypography>
          {/* heading end */}



          <VuiTypography
            style={{
              component: "h3",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            <li>
              Beginner-friendly with pre-designed templates
            </li>
            <li>
              Affordable pricing for small businesses
            </li>
            <li>
              Quick and easy app creation process
            </li>
          </VuiTypography>


          <br />



          {/* heading start */}
          <VuiTypography
            style={{
              component: "h1",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "2vw",
              fontWeight: "bold",
              color: "#fe3737",
              marginBottom: "20px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            AppMakr Cons
          </VuiTypography>
          {/* heading end */}



          <VuiTypography
            style={{
              component: "h3",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            <li>
              Limited customization options
            </li>
            <li>
              Fewer integrations compared to BuildFire
            </li>

          </VuiTypography>

          {/* bubble end */}




        </VuiBox>
        {/* section 7 end */}


        {/* section 8 start */}
        <VuiBox
          display="flex"
          justifyContent="space-between"
          flexDirection={isMobile ? "column" : "column"}
          // alignItems="start"
          width={isMobile ? "100%" : "90%"}
          marginTop="5%"
          padding="40px"
          style={{
            background: "white",
          }}
          borderRadius="25px"
        >
          <VuiTypography
            style={{
              component: "h1",

              textAlign: "center",
              fontSize: isMobile ? "6.5vw" : "3.2vw",
              fontWeight: "bold",
              color: "#fe3737",
              marginBottom: "50px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            Which No-Code Builder is Best for You?
          </VuiTypography>

          {/* heading start */}
          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "2vw",
              fontWeight: "bold",
              color: "#69696a",
              marginBottom: "20px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            <bold style={{ color: "black" }}>Which Tool is Faster to Master?</bold>
          </VuiTypography>
          {/* heading end */}

          {/* bubble */}
          <VuiTypography
            style={{
              component: "h3",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              fontWeight: 'bold',
              color: "#fe3737",
              marginBottom: "10px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            The choice between BuildFire and AppMakr largely depends on your app development goals

          </VuiTypography>


          <VuiTypography
            style={{
              component: "h3",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            <li>
              Choose BuildFire if you need a scalable, feature-rich platform for business apps or employee portals.

            </li>
            <li>
              Choose AppMakr if you’re a beginner looking to create a basic app quickly and affordably.

            </li>

            <br />

            Ultimately, both tools cater to different audiences and needs, so consider your project requirements before making a decision.
          </VuiTypography>
          {/* bubble end */}



        </VuiBox>
        {/* section 8 end */}


        {/* section 9 start */}
        <VuiBox
          display="flex"
          justifyContent="space-between"
          flexDirection={isMobile ? "column" : "column"}
          // alignItems="start"
          width={isMobile ? "100%" : "90%"}
          marginTop="5%"
          padding="40px"
          style={{
            background: "white",
          }}
          borderRadius="25px"
        >
          <VuiTypography
            style={{
              component: "h2",

              textAlign: "center",
              fontSize: isMobile ? "6.5vw" : "3.2vw",
              fontWeight: "bold",
              color: "#fe3737",
              marginBottom: "50px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            Explore the Future of App Development with AI-Powered Tools
          </VuiTypography>

          {/* heading start */}
          <VuiTypography
            style={{
              component: "h3",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              // fontWeight: "bold",
              color: "#69696a",
              marginBottom: "20px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            <bold style={{
              color: 'black', fontWeight: "bold", fontSize: isMobile ? "4.5vw" : "2vw",
            }}>
While platforms like BuildFire and AppMakr simplify app development, they still require time and effort to master. 
            </bold>
            <br />
            <br />

            That’s where AI-powered solutions like CodeGrow step in.
          </VuiTypography>
          {/* heading end */}

          {/* bubble */}
          <VuiTypography
            style={{
              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "2vw",
              fontWeight: 'bold',
              color: "black",
              marginBottom: "10px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            CodeGrow is a fully automated AI app developer that takes your app ideas and transforms them into functional iOS applications with minimal effort.
          </VuiTypography>


          <VuiTypography
            style={{
              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "2vw",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >


            If you’re looking to move beyond no-code tools and tap into the next level of automation, CodeGrow is here to help. Build iOS apps faster, smarter, and easier than ever before.
            Ready to take your app ideas to the next level?
            <br />
            <br />

            <center>
              <br />

              <VuiButton
                aligncontent="center"
                variant="contained"
                color="primary"
                size="medium"
                onClick={() => navigateToSignIn()}
                style={{
                  backgroundColor: '#1079FB',

                  color: " white",
                  fontSize: isMobile ? "3.5vw" : "1.5vw",
                  padding: "10px 20px",
                  borderRadius: '55px',
                  marginBottom: isMobile ? "10%" : "0px"

                }}
              >
                Start For Free Today
              </VuiButton>
              <br />
            </center>

          </VuiTypography>
          {/* bubble end */}



        </VuiBox>
        {/* section 9 end */}
      </VuiBox>




      {/* fourth section start */}
      <VuiBox
        style={{
          width: '100vw',
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
          backgroundColor: "#F7F8FB",

          minHeight: "100vh",
          marginTop: '0px',

          padding: "0", // Remove padding to avoid affecting width
        }}
      >





        {/* third white bubble*/}
        <VuiBox

          width={isMobile ? "100%" : "90%"}
          // maxWidth="800px"
          marginTop="5%"
          height={isMobile ? "150vw" : " 75vw"}
          padding="40px"
          style={{
            backgroundImage: `url(${minicolourbg})`, // Background image for the landing page

            backgroundSize: "cover", // Ensures the image covers the entire area
            backgroundPosition: "center", // Centers the image
            backgroundRepeat: "no-repeat", // Prevents the image from repeating
          }}
          borderRadius="25px"
        >
          <VuiBox
            display="flex"
            flexDirection="column"

            justifyContent={"space-between"}
            alignItems="center"
            marginLeft={isMobile ? "0px" : '15%'}
            width={isMobile ? "100%" : "70%"}
            // maxWidth="800px"

            height={" 100%"}
            padding="0px"

            borderRadius="25px"
          >


            <VuiTypography
              style={{
                textAlign: "center",
                fontSize: isMobile ? "6.5vw" : "5.8vw",
                fontWeight: "bold",
                color: "white",
                marginTop: "15px",
                lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here
              }}
            >
              <bold style={{
                color: "#FE3737",
              }}>               Start For Free Today
              </bold>
            </VuiTypography>


            <VuiTypography
              style={{
                marginTop: isMobile ? "10%" : "15px",

                textAlign: "center",
                fontSize: isMobile ? "5.5vw" : "1.6vw",
                fontWeight: "bold",
                color: "white",
                marginBottom: isMobile ? "6%" : "0px",
                lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here
              }}
            >

              Launch your first app project with expert guidance and AI tools.            </VuiTypography>
            <br />

            <VuiButton
              aligncontent="center"
              variant="contained"
              color="primary"
              size="medium"
              onClick={() => navigateToSignIn()}
              style={{
                backgroundColor: '#1079FB',

                color: " white",
                fontSize: isMobile ? "3.5vw" : "1.5vw",
                padding: "10px 20px",
                borderRadius: '55px',
                marginBottom: isMobile ? "10%" : "0px"

              }}
            >
              Start For Free Today
            </VuiButton>
            <br />
            <img
              src={macbook} // Replace with your GIF URL
              alt="Centered Image"
              style={{
                borderRadius: "15px",
                width: isMobile ? "90vw" : "70vw",
                objectFit: "contain", // Ensures the image scales nicely within the specified width
                marginBottom: "-0%", // Centers the image horizontally
                boxShadow: "0 0 20px 5px rgba(255, 255, 255, 0.7)", // Adds a white glow effect
              }}
            />


          </VuiBox>


        </VuiBox>
        {/* third white bubble end */}


        {/* FAQ */}
        <VuiBox
          style={{
            marginTop: "100px",
            textAlign: "center",
            padding: "40px 20px",


            background: "linear-gradient(to top, #030515 0%, #7f8c8d 70%, #f7f8fb 100%)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
          }}
        >
          <VuiTypography
            style={{
              fontSize: "48px",
              color: "#FC3837",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <b style={{ fontSize: isMobile ? "5.8vw" : "4.2vw", color: "black" }}>
              Frequently Asked Questions
            </b>
          </VuiTypography>

          {/* FAQ list */}
          <VuiBox
            style={{
              width: isMobile ? "90%" : "80%",
              maxWidth: "1200px",
              marginTop: "30px",
            }}
          >
            {faqData.map((faq, index) => (
              <VuiBox
                key={index}
                style={{
                  marginBottom: "20px",
                  padding: "20px",
                  background: "#1a1a2e",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                onClick={() => toggleQuestion(index)}
              >
                {/* Question */}
                <VuiTypography
                  style={{
                    color: "#FC3837",
                    fontSize: isMobile ? "4vw" : "1.5vw",
                  }}
                >
                  {faq.question}
                </VuiTypography>

                {/* Answer */}
                {openQuestion === index && (
                  <VuiTypography
                    style={{
                      marginTop: "10px",
                      fontSize: isMobile ? "4vw" : "1.2vw",
                      color: "white",
                      transition: "max-height 0.3s ease-in-out",
                      overflow: "hidden",
                    }}
                  >
                    {faq.answer}
                  </VuiTypography>
                )}
              </VuiBox>
            ))}
          </VuiBox>
        </VuiBox>
        {/* triple section end */}


      </VuiBox>
      {/* fourth section end */}



      <br />



    </CoverLayout>
    </>
  );
}

export default SignIn;
