import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";
import { auth } from '../../../firebase';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth"; // Import sendPasswordResetEmail
import CoverLayout from "layouts/authentication/components/BasicLayout";
import CircularProgress from "@mui/material/CircularProgress";
import bgSignIn from "assets/images/signInImage.png";
import radialGradient from "assets/theme/functions/radialGradient";
import rgba from "assets/theme/functions/rgba";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import bgDash from "assets/images/body-background.png"; // Replace with your background image
import bgDash2 from "assets/images/billing-background-balance.png"; // Replace with your background image
import { Height } from "@mui/icons-material";
import longlogo from "assets/images/logos/longlogo.png";
import longlogowhite from "assets/images/logos/longlogowhite.png";
import { IoMdCheckmarkCircle } from "react-icons/io";
import { FaPhotoVideo } from "react-icons/fa";
import { FaTools } from "react-icons/fa";
import { FaChrome } from "react-icons/fa";
import { FaProjectDiagram } from "react-icons/fa";
import { MdDesignServices } from "react-icons/md";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import RcSlider from 'rc-slider'; // Correct import statement
import 'rc-slider/assets/index.css'; // Import the default styles
import { Box, Typography } from '@mui/material'; // Ensure you're using MUI components

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

import laptop1 from "assets/images/laptops/1.png";
import laptop2 from "assets/images/laptops/2.png";
import laptop3 from "assets/images/laptops/3.png";
import laptop4 from "assets/images/laptops/4.png";
import { fontGrid } from "@mui/material/styles/cssUtils";

import proof1 from "assets/images/flippaproof/1.png";
import proof2 from "assets/images/flippaproof/2.png";
import proof3 from "assets/images/flippaproof/3.png";
import proof4 from "assets/images/flippaproof/4.png";
import proof5 from "assets/images/flippaproof/5.png";
import proof6 from "assets/images/flippaproof/6.png";
import proof7 from "assets/images/flippaproof/7.png";
import proof8 from "assets/images/flippaproof/8.png";
import proof9 from "assets/images/flippaproof/9.png";
import proof10 from "assets/images/flippaproof/10.png";

import { FaAppStoreIos } from "react-icons/fa";
import { PiPresentationChartFill } from "react-icons/pi";
import { FaVideo } from "react-icons/fa";
import { FaBrain } from "react-icons/fa";
import { IoIosSchool } from "react-icons/io";

import { useMediaQuery } from '@mui/material'; // Import useMediaQuery
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // carousel styles




function SignIn() {
  const navigate = useNavigate();
  const [appsPerMonth, setAppsPerMonth] = useState(2);
  const [dailyRevenue, setDailyRevenue] = useState(10);
  const [flippaSalePrice, setFlippaSalePrice] = useState(1500);
  const monthlyRevenuePerApp = dailyRevenue * 30; // 30 days in a month
  const yearlyRevenuePerApp = monthlyRevenuePerApp * 12;
  const totalRevenuePerMonth = (appsPerMonth * 12) * monthlyRevenuePerApp;
  const totalRevenuePerYear = totalRevenuePerMonth * 12;
  const totalFlippaRevenue = (appsPerMonth * 12) * flippaSalePrice;
  const totalCombinedRevenue = totalRevenuePerYear + totalFlippaRevenue;
  const totalCombinedMonthly = totalCombinedRevenue / 12;
  const isMobile = useMediaQuery('(max-width:600px)'); // Adjust breakpoint as needed
  const [openQuestion, setOpenQuestion] = useState(null);

  const toggleQuestion = (index) => {
    setOpenQuestion(openQuestion === index ? null : index);
  };

  const faqData = [
    {
      question: "What is included in the subscription?",
      answer:
        "The subscription includes access to our full library of AI development tools, 58 video lessons, full IOS apps ready for release, templates, AI Chrome extensions, coaching calls, and more."
    },
    {
      question: "What kind of educational resources does CodeGrow offer?",
      answer: "CodeGrow provides a rich library of training videos, articles, guides, and copy and paste projects to help you create more apps and continuously improve your skills."
    },
    {
      question: "How do I schedule a 1:1 coaching call?",
      answer:
        "You can schedule a 1:1 coaching call once you sign up for the yearly package by clicking the link in your email and selecting an availble slot that suits your schedule."
    },
    {
      question: "What programming languages are these apps made with?",
      answer:
        "Our apps are made with Flutter and Swift and have SQLite and Firebase Backends"
    },
    {
      question: "Is CodeGrow compatible with multiple devices?",
      answer: "Yes, CodeGrow is fully responsive and designed to work seamlessly across desktops, tablets, and smartphones, so you can manage your business from anywhere, at any time."
    },
    {
      question: "If I no longer want to use CodeGrow, can I cancel at any time?",
      answer: "Simply login to the portal, head to billing and follow the instructions to cancel your plan at any time."
    },
    {
      question: "Does CodeGrow have real-time communication for support?",
      answer: "Yes, you get real-time assistance from our support team, ensuring that you have all the support you need."
    }
  ];

  const featureItems = [
    {
      icon: <FaPhotoVideo style={{ marginRight: "0px", fontSize: isMobile ? "15vw" : "6vw" }} />,
      title: "Comprehensive Dashboard",
      description: "Provides an all-in-one overview of projects, monthly revenue, performance metrics, and key insights",
    },
    {
      icon: <FaVideo style={{ marginRight: "0px", fontSize: isMobile ? "15vw" : "6vw" }} />,
      title: "1:1 Coaching Calls",
      description: "Offers personalized, expert guidance to help improve coding skills, business strategies, and overall app development success.",
    },
    {
      icon: <IoIosSchool style={{ marginRight: "0px", fontSize: isMobile ? "15vw" : "6vw" }} />,
      title: "Resource Center",
      description: "Provides access to templates, guides, video courses, and other valuable materials to support full programming operations",
    },
    {
      icon: <FaTools style={{ marginRight: "0px", fontSize: isMobile ? "15vw" : "6vw" }} />,
      title: "Project Management Portal",
      description: "Provides comprehensive tracking of project insights, including version control, user engagement, revenue analytics, Flippa listings, ensuring all critical data is centralized.",
    },
    {
      icon: <FaBrain style={{ marginRight: "0px", fontSize: isMobile ? "15vw" : "6vw" }} />,
      title: "AI Development Tools",
      description: "Equips developers with advanced AI-driven resources to streamline coding, automate tasks, and optimize app performance.",
    },
    {
      icon: <FaProjectDiagram style={{ marginRight: "0px", fontSize: isMobile ? "15vw" : "6vw" }} />,
      title: "Ready To Launch Apps",
      description: "Provides pre-built, fully functional app templates, allowing users to quickly deploy and customize apps with minimal effort.",
    },
    {
      icon: <FaChrome style={{ marginRight: "0px", fontSize: isMobile ? "15vw" : "6vw" }} />,
      title: "AI Chrome Extensions",
      description: "Features powerful Chrome extensions that integrate AI capabilities to enhance productivity and streamline online development workflows.",
    },
    {
      icon: <PiPresentationChartFill style={{ marginRight: "0px", fontSize: isMobile ? "15vw" : "6vw" }} />,
      title: "Sales Guides And Pitch Decks",
      description: "Includes expertly crafted guides and templates for creating compelling sales pitches and investor-ready presentations.",
    },
    {
      icon: <FaAppStoreIos style={{ marginRight: "0px", fontSize: isMobile ? "15vw" : "6vw" }} />,
      title: "App Store Development Tools",
      description: "Offers a suite of tools designed to simplify the app store submission process, from app optimization to marketing strategies.",
    },
  ];

  const handleDownload = () => {
    const pdfUrl = "https://firebasestorage.googleapis.com/v0/b/codegrow-e0300.appspot.com/o/The%20Complete%20Guide%20to%20Building%20and%20Monetizing%20Simple%20iOS%20Apps%20with%20AI.pdf?alt=media&token=a779f081-5d00-48aa-acde-042d2dcec962"; // Firebase URL

    // Open the PDF in a new browser tab
    window.open(pdfUrl, "_blank");
  };



  return (
    <><CoverLayout >

      <VuiBox
        style={{
          width: '100vw',
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          // backgroundImage: `url(${bgDash2})`, // Background image for the landing page
          backgroundImage: "linear-gradient(to bottom, #120e26, #3036AF)",
          backgroundSize: "fill",
          backgroundPosition: "center",
          padding: "0 20px", // Add some padding for responsiveness
        }}
      >



        {/* top menu*/}
        <VuiBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width={isMobile ? "100%" : "80%"}
          // maxWidth="800px"
          marginTop="10px"
          padding="20px"
          background={rgba(palette.info.main, 0.8)}
          borderRadius={borders.borderRadius.md}
        >
          {/* Logo on the Left */}
          <VuiBox display="flex" alignItems="center">
            <img
              src={longlogowhite} // Remove the `${}`
              alt="Logo"
              style={{ height: isMobile ? "17vw" : "10vw", marginBottom: '-20px' }}
            />

          </VuiBox>

          {/* Buttons on the Right */}
          <VuiBox display="flex" gap="20px" style={{ marginBottom: '-20px' }}
          >
            <VuiButton
              variant="contained"
              color="primary"
              size="medium"
              onClick={() => navigate("/authentication/sign-in")}
              style={{
                backgroundColor: palette.info.main,
                color: "#fff",
                fontSize: isMobile ? "3.5vw" : "1.5vw",
                padding: "10px 20px",
              }}
            >
              Go To Portal
            </VuiButton>

          </VuiBox>
        </VuiBox>
        {/* top menu end */}


        {/* Hero Section */}
        <VuiBox
          style={{
            textAlign: "center",
            maxWidth: isMobile ? "100vw" : "80vw",
            margin: "auto",
            // background: rgba(palette.info.main, 0.8),
            borderRadius: borders.borderRadius.md,
            padding: "40px",
          }}
        >

          {/* top text */}
          <VuiTypography style={{ fontSize: isMobile ? "4.5vw" : "2.5vw", color: "white", marginBottom: "20px" }}>
            Looking to Build an Online Income?
          </VuiTypography>

          <VuiTypography
            style={{
              fontSize: isMobile ? "5.8vw" : "3.1vw", // Adjusts according to the viewport width
              fontWeight: "bold",
              color: "white",
              marginBottom: "15px",
              textAlign: "center", // Centers the text
            }}
          >
            The Easiest Way To Earn <b style={{ color: '#FC3837' }}>  $10k/month </b>
            By Coding Fully Monetised IOS Apps Using AI In Under 7 Days
          </VuiTypography>

          {/* <VuiButton
            variant="contained"
            color="primary"
            size="large"
            onClick={() => navigate("/authentication/sign-up")}
            style={{
              backgroundColor: palette.info.main,
              color: "#fff",
              fontSize: isMobile ? "3.8vw" : "1.7vw", // Adjusts according to the viewport width
              padding: "15px 30px",
              marginTop: '30px'
            }}
          >
            Get Started Today
          </VuiButton> */}

          <VuiButton
            variant="contained"
            color="primary"
            size="large"
            onClick={handleDownload}
            style={{
              backgroundColor: palette.info.main,
              color: "#fff",
              fontSize: isMobile ? "3.8vw" : "1.7vw", // Adjusts according to the viewport width
              padding: "15px 30px",
              marginTop: '30px'
            }}
          >
            Download Our Free A.I. Cheat Code
          </VuiButton>
          {/* <VuiTypography style={{color:'white'}}>Get Started in Less Than 2 Minutes</VuiTypography> */}
          {/* top text end */}


          {/* triple section menu*/}
          <VuiBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            // maxWidth="800px"
            marginTop="30px"
            padding="20px"
            background={rgba(palette.info.main, 0.8)}
            borderRadius={borders.borderRadius.md}
            flexDirection={isMobile ? "column" : "row"}
          >
            <VuiTypography
              style={{
                fontSize: "48px",
                color: "#FC3837",
                marginBottom: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <IoMdCheckmarkCircle style={{ marginRight: "10px", fontSize: isMobile ? "4.5vw" : "3.5vw" }} /> {/* Icon size and margin */}
              <b style={{ fontSize: isMobile ? "4vw" : "1.2vw", color: 'white' }}>Zero Computer Skills Needed</b>
            </VuiTypography>

            <VuiTypography
              style={{
                fontSize: "48px",
                color: "#FC3837",
                marginBottom: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <IoMdCheckmarkCircle style={{ marginRight: "10px", fontSize: isMobile ? "4.5vw" : "3.5vw" }} /> {/* Icon size and margin */}
              <b style={{ fontSize: isMobile ? "4vw" : "1.2vw", color: 'white' }}>Works for All Ages</b>
            </VuiTypography>

            <VuiTypography
              style={{
                fontSize: "48px",
                color: "#FC3837",
                marginBottom: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <IoMdCheckmarkCircle style={{ marginRight: "10px", fontSize: isMobile ? "4.5vw" : "3.5vw" }} /> {/* Icon size and margin */}
              <b style={{ fontSize: isMobile ? "4vw" : "1.2vw", color: 'white' }}>Scale From Home With Ease</b>
            </VuiTypography>



          </VuiBox>
          {/* triple section end */}

          {/* Video Section */}
          <VuiBox
            style={{
              marginTop: "80px",
              textAlign: "center",
              width: "100%",

              margin: "auto",
              padding: isMobile ? "10px" : "40px 20px",
              borderRadius: borders.borderRadius.md,
              background: rgba(palette.info.main, 0.8),
            }}
          >
            <VuiTypography
              style={{
                fontSize: isMobile ? "3.6vw" : "2vw",
                fontWeight: "bold",
                color: "white",
                marginBottom: "20px",
              }}
            >
              Learn More In The Video Below And Sign Up For $25 Today!
            </VuiTypography>
            <VuiBox
              style={{
                position: "relative",
                paddingBottom: "56.25%", // Aspect ratio 16:9
                height: 0,
                overflow: "hidden",
                maxWidth: "100%",
                backgroundColor: "black", // Optional background for loading state
              }}
            >
              <iframe
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  borderRadius: borders.borderRadius.md,
                }}
                src="https://www.youtube.com/embed/03z_t-xWHmI?si=5GAXjyeA3vxPuVPP" // Replace VIDEO_ID with actual YouTube video ID
                title="Introduction Video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </VuiBox>

          </VuiBox>
          {/* video section end */}



          {/* triple section menu*/}
          <VuiBox
            style={{
              marginTop: "100px",
              textAlign: "center",
              padding: "50px 30px",
              background: "white",
              borderRadius: borders.borderRadius.md,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              flexDirection: isMobile ? "column" : 'row'
            }}
          >

            <VuiTypography
              style={{
                fontSize: "48px",
                color: "#FC3837",
                display: "flex",
                flexDirection: isMobile ? "row" : 'column',
                alignItems: "center",
                justifyContent: "space-between",
                gap: '20px',
                marginBottom: isMobile ? "20px" : "0px",
                width: isMobile ? '100%' : ''
              }}
            >
              <FaPhotoVideo style={{ marginRight: "0px", fontSize: isMobile ? "12vw" : "6vw" }} /> {/* Icon size and margin */}
              <b style={{ fontSize: isMobile ? "3.5vw" : "1.3vw", color: 'black' }}>58 Video Lessons</b>
            </VuiTypography>

            <VuiTypography
              style={{
                fontSize: "48px",
                color: "#FC3837",
                display: "flex",
                flexDirection: isMobile ? "row" : 'column',
                alignItems: "center",
                justifyContent: "space-between",
                gap: '20px',
                marginBottom: isMobile ? "20px" : "0px",
                width: isMobile ? '100%' : ''
              }}
            >
              <FaTools style={{ marginRight: "0px", fontSize: isMobile ? "12vw" : "6vw" }} /> {/* Icon size and margin */}
              <b style={{ fontSize: isMobile ? "3.5vw" : "1.3vw", color: 'black' }}>AI Development Tools</b>
            </VuiTypography>

            <VuiTypography
              style={{
                fontSize: "48px",
                color: "#FC3837",
                display: "flex",
                flexDirection: isMobile ? "row" : 'column',
                alignItems: "center",
                justifyContent: "space-between",
                gap: '20px',
                marginBottom: isMobile ? "20px" : "0px",
                width: isMobile ? '100%' : ''
              }}
            >
              <FaProjectDiagram style={{ marginRight: "0px", fontSize: isMobile ? "12vw" : "6vw" }} /> {/* Icon size and margin */}
              <b style={{ fontSize: isMobile ? "3.5vw" : "1.3vw", color: 'black' }}>{isMobile ? "Management System" : "Project Management System"}</b>
            </VuiTypography>

            <VuiTypography
              style={{
                fontSize: "48px",
                color: "#FC3837",
                display: "flex",
                flexDirection: isMobile ? "row" : 'column',
                alignItems: "center",
                justifyContent: "space-between",
                gap: '20px',
                marginBottom: isMobile ? "20px" : "0px",
                width: isMobile ? '100%' : ''
              }}
            >

              <FaChrome style={{ fontSize: isMobile ? '12vw' : "6vw" }} /> {/* Icon size and margin */}
              <b style={{ fontSize: isMobile ? '3.5vw' : "1.3vw", color: 'black' }}>AI Chrome Extensions</b>
            </VuiTypography>

            <VuiTypography
              style={{
                fontSize: "48px",
                color: "#FC3837",
                display: "flex",
                flexDirection: isMobile ? "row" : 'column',
                alignItems: "center",
                aligncontent: "end",
                justifyContent: "space-between",
                gap: '20px',
                marginBottom: isMobile ? "20px" : "0px",
                width: isMobile ? '100%' : ''
              }}
            >
              <MdDesignServices style={{ marginRight: "0px", fontSize: isMobile ? '12vw' : "6vw" }} /> {/* Icon size and margin */}
              <b style={{ fontSize: isMobile ? '3.5vw' : "1.3vw", color: 'black' }}>{isMobile ? 'Design Templates' : 'Design And Release Templates'}</b>
            </VuiTypography>

            <VuiTypography
              style={{
                fontSize: "48px",
                color: "#FC3837",
                display: "flex",
                flexDirection: isMobile ? "row" : 'column',
                alignItems: "center",
                justifyContent: "space-between",
                gap: '20px',
                marginBottom: isMobile ? "20px" : "0px",
                width: isMobile ? '100%' : ''
              }}
            >
              <FaMoneyBillTrendUp style={{ marginRight: "0px", fontSize: isMobile ? '12vw' : "6vw" }} /> {/* Icon size and margin */}
              <b style={{ fontSize: isMobile ? '3.5vw' : "1.3vw", color: 'black' }}>Sale Templates</b>
            </VuiTypography>



          </VuiBox>
          {/* triple section end */}

          <VuiButton
            variant="contained"
            color="primary"
            size="large"
            onClick={() => navigate("/authentication/sign-up")}
            style={{
              backgroundColor: palette.info.main,
              color: "#fff",
              fontSize: isMobile ? '3.8vw' : "1.7vw", // Adjusts according to the viewport width
              padding: "15px 30px",
              marginTop: '50px'
            }}
          >
            Get Started Today
          </VuiButton>
        </VuiBox>

        {/* course breakdown */}
        <VuiTypography style={{ fontSize: isMobile ? '4.5vw' : "2vw", color: "#FC3837", marginBottom: isMobile ? '0px' : "-10px", marginTop: "20px" }}>
          <b>
            THE PLAN
          </b>
        </VuiTypography>

        <VuiTypography
          style={{
            fontSize: isMobile ? '6.2vw' : "4.5vw", // Adjusts according to the viewport width

            color: "white",
            marginBottom: "-10px",
            textAlign: "center", // Centers the text
          }}
        >
          Here's What's Inside
        </VuiTypography>


        {/* Features Section */}
        <VuiBox
          style={{
            display: "flex",
            justifyContent: isMobile ? 'center' : "space-evenly",
            alignItems: isMobile ? 'center' : "flex-start",
            flexDirection: isMobile ? 'column' : 'row',
            marginTop: isMobile ? '45px' : "80px",
            // maxWidth: "1200px",
            width: "100%",
            padding: "0 20px",
          }}
        >
          <VuiBox style={{ backgroundColor: '#110e27', padding: '30px', width: isMobile ? '90%' : '40%', borderRadius: '15px' }}>
            <img style={{ width: '100%' }} src={laptop1}             ></img>
            <VuiTypography style={{ fontSize: isMobile ? '4.5vw' : "1.8vw", fontWeight: "bold", color: "white" }}>
              Module 1: Foundation and Setup
            </VuiTypography>
            <VuiTypography style={{ fontSize: isMobile ? '4vw' : "1.4vw", color: "white", marginTop: "10px" }}>
              This module covers iOS development basics, including setting up of Xcode, VSCode and Firebase, using ChatGPT and Canva, app niche research, creating and launching a Flutter project on an iPhone simulator.            </VuiTypography>
          </VuiBox>

          <VuiBox style={{ backgroundColor: '#110e27', padding: '30px', width: isMobile ? '90%' : '40%', borderRadius: '15px', marginTop: isMobile ? '25px' : '0px', }}>
            <img style={{ width: '100%' }} src={laptop2}             ></img>

            <VuiTypography style={{ fontSize: isMobile ? '4.5vw' : "1.8vw", fontWeight: "bold", color: "white" }}>
              Module 2: Learning Core Skills
            </VuiTypography>
            <VuiTypography style={{ fontSize: isMobile ? '4vw' : '1.4vw', color: "white", marginTop: "10px" }}>
              This module covers core iOS development skills, including Swift, Flutter, ChatGPT overviews, hands-on coding, project management, local storage, Firebase, AI integration, and a GitHub guide for version control.
            </VuiTypography>
          </VuiBox>

        </VuiBox>

        {/* Features Section */}
        <VuiBox
          style={{
            display: "flex",
            flexDirection: isMobile ? 'column' : "row",
            justifyContent: isMobile ? 'center' : "space-evenly",
            alignItems: isMobile ? 'center' : "flex-start",
            marginTop: isMobile ? '25px' : "80px",
            // maxWidth: "1200px",
            width: "100%",
            padding: "0 20px",
          }}
        >
          <VuiBox style={{ backgroundColor: '#110e27', padding: '30px', width: isMobile ? '90%' : '40%', borderRadius: '15px' }}>
            <img style={{ width: '100%' }} src={laptop3}             ></img>
            <VuiTypography style={{ fontSize: isMobile ? '4.5vw' : "1.8vw", fontWeight: "bold", color: "white" }}>
              Module 3: AI Integration & Rapid App Development
            </VuiTypography>
            <VuiTypography style={{ fontSize: isMobile ? '4vw' : "1.4vw", color: "white", marginTop: "10px" }}>
              This module focuses on AI integration and rapid app development, covering AdMob setup in Flutter, creating project templates, and app testing. It also includes guidance on publishing apps to the App Store, including screenshots, descriptions, and regional setup.            </VuiTypography>
          </VuiBox>
          <VuiBox style={{ backgroundColor: '#110e27', padding: '30px', width: isMobile ? '90%' : '40%', borderRadius: '15px', marginTop: isMobile ? '25px' : "0px", }}>
            <img style={{ width: '100%' }} src={laptop4}             ></img>
            <VuiTypography style={{ fontSize: isMobile ? '4.5vw' : "1.8vw", fontWeight: "bold", color: "white" }}>
              Module 4: Marketing & Monetisation Strategies
            </VuiTypography>
            <VuiTypography style={{ fontSize: isMobile ? '4vw' : "1.4vw", color: "white", marginTop: "10px" }}>
              This module covers marketing and monetization strategies, including App Store search ads, ASO, social media tactics, and paid ad strategies. It also addresses ad revenue models, launch marketing campaigns, and analyzing user feedback to improve app functionality.            </VuiTypography>
          </VuiBox>

        </VuiBox>

        {/* course breakdown end */}


        <VuiButton
          variant="contained"
          color="primary"
          size="large"
          onClick={() => navigate("/authentication/sign-up")}
          style={{
            backgroundColor: palette.info.main,
            color: "#fff",
            fontSize: isMobile ? '3.8vw' : "1.7vw", // Adjusts according to the viewport width
            padding: "15px 30px",
            marginTop: '50px',
            marginBottom: '50px'

          }}
        >
          Get Started Today
        </VuiButton>
        {/* Video Section */}
        <VuiBox
          style={{
            marginTop: "5vw",
            textAlign: "center",
            width: "80%",

            margin: "auto",
            padding: isMobile ? "10px" : "40px 20px",
            borderRadius: borders.borderRadius.md,
            background: rgba(palette.info.main, 0.8),
          }}
        >
          <VuiTypography
            style={{
              fontSize: isMobile ? "3.6vw" : "2vw",
              fontWeight: "bold",
              color: "white",
              marginBottom: "20px",
            }}
          >
            Watch The Live Demo Below And Sign Up Today For Just $25!
          </VuiTypography>
          <VuiBox
            style={{
              position: "relative",
              paddingBottom: "56.25%", // Aspect ratio 16:9
              height: 0,
              overflow: "hidden",
              maxWidth: "100%",
              backgroundColor: "black", // Optional background for loading state
            }}
          >
            <iframe
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                borderRadius: borders.borderRadius.md,
              }}
              src="https://www.youtube.com/embed/DEvKyrrTAuM?si=7l-2-5kaSm5GBw2q" // Replace VIDEO_ID with actual YouTube video ID
              title="Introduction Video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </VuiBox>

        </VuiBox>
        {/* video section end */}


        {/* CTA Section */}
        <VuiBox
          style={{
            marginTop: isMobile ? '45px' : "100px",
            textAlign: "center",
            padding: isMobile ? '20px' : "40px 20px",
            background: "white",
            width: '86.6%',
            borderRadius: borders.borderRadius.md,
          }}
        >
          <VuiTypography
            style={{
              fontSize: isMobile ? '5vw' : "2.4vw",
              fontWeight: "bold",
              color: "black",
              marginBottom: "20px",
            }}
          >
            Ready to Start Making Monetised iOS Apps in <b style={{ color: '#FC3837' }}> Under 7 Days</b> ?
          </VuiTypography>
          <VuiButton
            variant="contained"
            color="primary"
            size="large"
            onClick={() => navigate("/authentication/sign-up")}
            style={{
              backgroundColor: palette.info.main,
              color: "#fff",
              fontSize: isMobile ? '3.8vw' : "1.7vw", // Adjusts according to the viewport width
              padding: "15px 30px",
              marginTop: isMobile ? "10px" : '20px'
            }}
          >
            Get Started Today
          </VuiButton>
        </VuiBox>
        {/* cta end */}

        {/* price breakdown */}
        <VuiTypography style={{ fontSize: isMobile ? '5.5vw' : "2vw", color: "#FC3837", marginBottom: isMobile ? '0px' : "-10px", marginTop: "50px" }}>
          <b>
            Packages
          </b>
        </VuiTypography>

        <VuiTypography
          style={{
            fontSize: isMobile ? '7vw' : "4.5vw", // Adjusts according to the viewport width

            color: "white",
            marginBottom: "-10px",
            textAlign: "center", // Centers the text
          }}
        >
          Tailored To You
        </VuiTypography>


        {/* Features Section */}
        <VuiBox
          style={{
            display: "flex",
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: isMobile ? 'center' : "space-evenly",
            alignItems: isMobile ? 'center' : "flex-start",
            marginTop: isMobile ? '25px' : "80px",
            width: "100%",
            padding: "0 20px",
          }}
        >
          {/* Monthly Package */}
          <VuiBox style={{ backgroundColor: '#110e27', padding: '30px', width: isMobile ? '100%' : '30%', borderRadius: '15px' }}>
            <VuiBox
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                flexDirection: "row",
                width: "100%",
                padding: isMobile ? '0px' : "0 20px",

              }}
            >
              <VuiTypography style={{ fontSize: isMobile ? '5.8vw' : "1.6vw", fontWeight: "bold", color: "white" }}>
                Monthly Package
              </VuiTypography>
              <VuiTypography style={{ fontSize: isMobile ? '5.8vw' : "1.6vw", fontWeight: "bold", color: "#FC3837" }}>
                $25
              </VuiTypography>
            </VuiBox>
            <br />
            <VuiTypography style={{ fontSize: isMobile ? '4vw' : "1.2vw", color: "white", marginTop: "10px" }}>
              {[
                'Unlimited Access To Full Course',
                'AI App Maker',
                'Canva Design Templates',
                'Flippa Sale Templates',
                'Projects Manager',
                'Ai Chrome Extensions',
                'App Store Design Lab'
              ].map((feature, index) => (
                <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', padding: '5px' }}>
                  <span style={{ color: 'white', marginRight: '8px' }}>✔</span> {feature}
                </li>
              ))}
            </VuiTypography>
            <center>
              <VuiButton
                variant="contained"
                color="primary"
                size="large"
                onClick={() => navigate("/authentication/sign-up")}
                style={{
                  backgroundColor: palette.info.main,
                  color: "#fff",
                  fontSize: isMobile ? '3.8vw' : "1.2vw", // Adjusts according to the viewport width
                  padding: "15px 30px",
                  marginTop: '20px',
                  width: '100%'
                }}
              >
                Get Started Today
              </VuiButton>
            </center>
          </VuiBox>

          {/* Yearly Package with Ribbon */}
          <VuiBox style={{ position: 'relative', backgroundColor: '#110e27', padding: '30px', width: isMobile ? '100%' : '30%', borderRadius: '15px', marginTop: isMobile ? '45px' : "0px", }}>

            {/* Ribbon */}
            <VuiBox
              style={{
                position: 'absolute',
                top: isMobile ? '-2.5%' : '-3%',
                left: '50%',
                transform: 'translateX(-50%)', // Centers the ribbon horizontally
                backgroundColor: '#FFD700', // Yellow ribbon, use '#FC3837' for red
                color: '#000', // Dark text for contrast
                padding: '5px 15px',
                fontWeight: 'bold',
                borderRadius: '5px',
                zIndex: 1,
                textAlign: 'center',
                width: '80%'
              }}
            > <VuiTypography style={{ fontSize: isMobile ? "3.4vw" : "1.2vw", fontWeight: "bold", color: "black" }}>
                Bonus: 1:1 Coaching + Ready Apps
              </VuiTypography>
            </VuiBox>


            <VuiBox
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                flexDirection: "row",
                width: "100%",
                padding: isMobile ? '0px' : "0 20px",

              }}
            >
              <VuiTypography style={{ fontSize: isMobile ? '5.8vw' : "1.6vw", fontWeight: "bold", color: "white" }}>
                Yearly Package
              </VuiTypography>
              <div>
                <VuiTypography style={{ fontSize: isMobile ? '5.8vw' : "1.6vw", fontWeight: "bold", color: "#FC3837" }}>
                  $250
                </VuiTypography>
              </div>
            </VuiBox>
            <br />
            <VuiTypography style={{ fontSize: isMobile ? '4.8vw' : "1.2vw", color: "white", marginTop: "10px" }}>
              {[
                'Unlimited Access To Full Course',
                'AI App Maker',
                'Canva Design Templates',
                'Flippa Sale Templates',
                'Projects Manager',
                'Ai Chrome Extensions',
                'App Store Design Lab'
              ].map((feature, index) => (
                <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', padding: '5px' }}>
                  <span style={{ color: 'white', marginRight: '8px' }}>✔</span> {feature}
                </li>
              ))}
            </VuiTypography>
            <center>
              <VuiButton
                variant="contained"
                color="primary"
                size="large"
                onClick={() => navigate("/authentication/sign-up")}
                style={{
                  backgroundColor: palette.info.main,
                  color: "#fff",
                  fontSize: isMobile ? '3.8vw' : "1.2vw", // Adjusts according to the viewport width
                  padding: "15px 30px",
                  marginTop: '20px',
                  width: '100%'
                }}
              >
                Get Started Today
              </VuiButton>
            </center>
          </VuiBox>

        </VuiBox>

        {/* price breakdown end */}


        {/* features */}

        <br />
        <br />

        <VuiTypography
          style={{
            fontSize: isMobile ? "5.6vw" : "3.5vw", // Adjusts according to the viewport width
            fontWeight: 'bold',
            color: "white",
            marginBottom: "-10px",
            textAlign: "center", // Centers the text
          }}
        >
          CodeGrow Gives You Everything You Need To Scale to <b style={{ color: '#FC3837' }}>$10k/month</b> Online
        </VuiTypography>


        <VuiBox
          style={{
            marginTop: "100px",
            textAlign: "center",
            padding: "20px ",
            background: "white",
            borderRadius: '12px', // assuming you're using borders.borderRadius.md
            display: "flex",
            flexDirection: 'column',
            justifyContent: "space-evenly",
            alignItems: "center",
            width: "80vw",
          }}
        >
          {isMobile ? (
            <Carousel showThumbs={false} infiniteLoop={true} showStatus={false} autoPlay>
              {featureItems.map((item, index) => (
                <div key={index} style={{ padding: "30px 0" }}>
                  <VuiTypography
                    style={{
                      fontSize: "48px",
                      color: "#FC3837",
                      display: "flex",
                      flexDirection: 'column',
                      alignItems: "center",
                      justifyContent: "start",
                      gap: '20px',
                      width: "100%",
                      minHeight: "300px",
                    }}
                  >
                    {item.icon}
                    <div style={{ width: '80vw' }}>
                      <b style={{ fontSize: "5.5vw", color: 'black' }}>{item.title}</b>
                    </div>

                    <div style={{ width: '70vw' }}>
                      <p style={{ fontSize: "5vw", color: 'black' }}>
                        {item.description}
                      </p>
                    </div>
                  </VuiTypography>
                </div>
              ))}
            </Carousel>
          ) : (
            <>
              {/* Desktop Layout: Split into rows */}
              <VuiBox
                style={{
                  textAlign: "center",
                  padding: "50px ",
                  background: "white",
                  borderRadius: '12px',
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {featureItems.slice(0, 3).map((item, index) => (
                  <VuiTypography
                    key={index}
                    style={{
                      fontSize: "48px",
                      color: "#FC3837",
                      display: "flex",
                      flexDirection: 'column',
                      alignItems: "center",
                      justifyContent: "start",
                      gap: '20px',
                      width: '30%',
                      minHeight: "300px",
                    }}
                  >
                    {item.icon}
                    <b style={{ fontSize: "1.3vw", color: 'black' }}>{item.title}</b>
                    <p style={{ fontSize: "1.3vw", color: 'black' }}>
                      {item.description}
                    </p>
                  </VuiTypography>
                ))}
              </VuiBox>

              <VuiBox
                style={{
                  textAlign: "center",
                  padding: "50px ",
                  background: "white",
                  borderRadius: '12px',
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {featureItems.slice(3, 6).map((item, index) => (
                  <VuiTypography
                    key={index}
                    style={{
                      fontSize: "48px",
                      color: "#FC3837",
                      display: "flex",
                      flexDirection: 'column',
                      alignItems: "center",
                      justifyContent: "start",
                      gap: '20px',
                      width: '30%',
                      minHeight: "300px",
                    }}
                  >
                    {item.icon}
                    <b style={{ fontSize: "1.3vw", color: 'black' }}>{item.title}</b>
                    <p style={{ fontSize: "1.3vw", color: 'black' }}>
                      {item.description}
                    </p>
                  </VuiTypography>
                ))}
              </VuiBox>

              {/* Add additional rows as needed */}
            </>
          )}
        </VuiBox>

        <br />
        <VuiButton
          variant="contained"
          color="primary"
          size="large"
          onClick={() => navigate("/authentication/sign-up")}
          style={{
            backgroundColor: palette.info.main,
            color: "#fff",
            fontSize: isMobile ? "3.8vw" : "1.2vw", // Adjusts according to the viewport width
            padding: "15px 30px",
            marginTop: '20px',

          }}
        >
          Get Started Today
        </VuiButton>

        <br />
        <br />
        <VuiTypography
          style={{
            fontSize: isMobile ? "5.8vw" : "3.5vw", // Adjusts according to the viewport width
            fontWeight: 'bold',
            color: "white",
            marginBottom: "-10px",
            textAlign: "center", // Centers the text
          }}
        >
          How Much Can I Earn With  <b style={{ color: '#FC3837' }}>CodeGrow</b>?
        </VuiTypography>
        <br />
        <br />
        {/* revenue */}
        <VuiBox
          style={{
            width: isMobile ? "90vw" : "70vw",
            height: "auto",
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            borderRadius: "15px",
            overflow: "hidden",
            backgroundColor: "#110e27"
          }}
        >
          {/* Left side */}
          <VuiBox
            style={{
              flex: 1,
              backgroundColor: "transparent", // Light blue with transparency
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              padding: "20px",
            }}
          >
            <VuiTypography style={{ fontWeight: "bold", fontSize: isMobile ? "5vw" : '1.5vw', color: 'white' }}>
              Revenue Configurator
            </VuiTypography>

            <VuiBox
              style={{
                flex: 1,
                backgroundColor: "transparent", // Light blue with transparency
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                padding: "20px",
              }}
            >
              {/* 1st section */}
              <VuiBox>
                <VuiTypography style={{ fontSize: isMobile ? "4vw" : '1.2vw', color: "white" }}>
                  Apps made per month
                </VuiTypography>
                <RcSlider
                  min={1}
                  max={6}
                  value={appsPerMonth}
                  onChange={value => setAppsPerMonth(value)}
                  trackStyle={[{ backgroundColor: '#1e90ff' }]} // Customize track color
                  handleStyle={[{ borderColor: '#1e90ff', backgroundColor: 'white' }]} // Customize handle color
                  railStyle={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }} // Customize rail color
                />
                <VuiTypography style={{ fontSize: isMobile ? "3.7vw" : '1.2vw', color: "white" }}> {appsPerMonth} apps per month</VuiTypography>
              </VuiBox>

              {/* 2nd section */}
              <VuiBox style={{ marginTop: isMobile ? "15px" : "0px" }}>
                <VuiTypography style={{ fontSize: isMobile ? "4vw" : '1.2vw', color: "white" }}>
                  Daily Ad Revenue Per App
                </VuiTypography>
                <RcSlider
                  min={5}
                  max={50}
                  value={dailyRevenue}
                  onChange={setDailyRevenue} // Simplified onChange
                  trackStyle={[{ backgroundColor: '#1e90ff' }]}
                  handleStyle={[{ borderColor: '#1e90ff', backgroundColor: 'white' }]}
                  railStyle={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }}
                />
                <VuiTypography style={{ fontSize: isMobile ? "3.7vw" : '1.2vw', color: "white" }}> ${dailyRevenue} per day</VuiTypography>
              </VuiBox>

              {/* 3rd section */}
              <VuiBox style={{ marginTop: isMobile ? "15px" : "0px" }}>
                <VuiTypography style={{ fontSize: isMobile ? "4vw" : '1.2vw', color: "white" }}>
                  Flippa Sale Price Per App
                </VuiTypography>
                <RcSlider
                  min={1000}
                  max={25000}
                  value={flippaSalePrice}
                  onChange={setFlippaSalePrice} // Simplified onChange
                  trackStyle={[{ backgroundColor: '#1e90ff' }]}
                  handleStyle={[{ borderColor: '#1e90ff', backgroundColor: 'white' }]}
                  railStyle={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }}
                />
                <VuiTypography style={{ fontSize: isMobile ? "3.7vw" : '1.2vw', color: "white" }}>${flippaSalePrice} per app</VuiTypography>
              </VuiBox>
            </VuiBox>

          </VuiBox>


          {/* Right side */}
          <VuiBox
            style={{
              flex: 1,
              backgroundColor: "white",
              borderRadius: "15px",
              padding: "20px",
            }}
          >
            <VuiTypography style={{ fontWeight: "bold", fontSize: isMobile ? "5vw" : '1.6vw', color: '#FC3837' }}>
              Revenue Breakdown
            </VuiTypography>

            <TableContainer component={Paper} style={{ marginTop: '20px', }}>
              <Table style={{ backgroundColor: 'white' }}>

                <TableBody style={{ backgroundColor: 'white' }}>
                  <TableRow style={{ backgroundColor: 'white' }}>
                    <TableCell style={{ fontSize: isMobile ? "4vw" : '1.4vw' }}><strong>Apps made in a month:</strong></TableCell>
                    <TableCell align="right" style={{ fontSize: isMobile ? "4.5vw" : '1.4vw' }}>{appsPerMonth}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontSize: isMobile ? "4vw" : '1.4vw' }}><strong>Apps made in a year:</strong></TableCell>
                    <TableCell align="right" style={{ fontSize: isMobile ? "4vw" : '1.4vw' }}>{appsPerMonth * 12}</TableCell>
                  </TableRow>
                  <hr />
                  <TableRow>
                    <TableCell style={{ fontSize: isMobile ? "4vw" : '1.4vw' }}><strong>Monthly Ad revenue per app:</strong></TableCell>
                    <TableCell align="right" style={{ fontSize: isMobile ? "4vw" : '1.4vw' }}>${monthlyRevenuePerApp.toLocaleString()}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontSize: isMobile ? "4vw" : '1.4vw', }}><strong>Yearly Ad revenue per app:</strong></TableCell>
                    <TableCell align="right" style={{ fontSize: isMobile ? "4vw" : '1.4vw' }}>${yearlyRevenuePerApp.toLocaleString()}</TableCell>
                  </TableRow>
                  <hr />
                  <TableRow>
                    <TableCell style={{ fontSize: isMobile ? "4vw" : '1.4vw' }}><strong>Total revenue from apps in a year:</strong></TableCell>
                    <TableCell align="right" style={{ fontSize: isMobile ? "4vw" : '1.4vw' }}>${totalRevenuePerYear.toLocaleString()}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontSize: isMobile ? "4vw" : '1.4vw' }}><strong>Monthly Income Breakdown:</strong></TableCell>
                    <TableCell align="right" style={{ fontSize: isMobile ? "4vw" : '1.4vw' }}>${totalRevenuePerMonth.toLocaleString()}</TableCell>
                  </TableRow>
                  <hr />

                  <TableRow>
                    <TableCell style={{ fontSize: isMobile ? "4vw" : '1.4vw' }}><strong>Total from selling apps on Flippa:</strong></TableCell>
                    <TableCell align="right" style={{ fontSize: isMobile ? "4vw" : '1.4vw' }}>${totalFlippaRevenue.toLocaleString()}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontSize: isMobile ? "4vw" : '1.4vw' }}><strong>Total combined revenue in a year:</strong></TableCell>
                    <TableCell align="right" style={{ fontSize: isMobile ? "4vw" : '1.4vw' }}>${totalCombinedRevenue.toLocaleString()}</TableCell>
                  </TableRow>
                  <hr />

                </TableBody>

              </Table>
            </TableContainer>

            <VuiTypography style={{ fontWeight: "bold", marginTop: "20px", fontSize: isMobile ? "4.8vw" : '1.6vw', color: 'green' }}>
              <strong>Total monthly revenue:</strong> ${totalCombinedMonthly.toLocaleString()}
            </VuiTypography>
          </VuiBox>

        </VuiBox>

        {/* revenue end */}
        <br />
        <VuiButton
          variant="contained"
          color="primary"
          size="large"
          onClick={() => navigate("/authentication/sign-up")}
          style={{
            backgroundColor: palette.info.main,
            color: "#fff",
            fontSize: isMobile ? "3.8vw" : "1.2vw", // Adjusts according to the viewport width
            padding: "15px 30px",
            marginTop: '20px',

          }}
        >
          Get Started Today
        </VuiButton>

        <br />

        {/* features end */}


        <br />
        <br />
        <VuiTypography
          style={{
            fontSize: isMobile ? "5.8vw" : "3.5vw", // Adjusts according to the viewport width
            fontWeight: 'bold',
            color: "white",
            marginBottom: "-10px",
            textAlign: "center", // Centers the text
          }}
        >
          How Much Are Simple  <b style={{ color: '#FC3837' }}>IOS Apps</b> Selling For?
        </VuiTypography>
        <br />
        <br />

        {/* flippa */}
        <VuiBox
          style={{
            flex: 1,
            backgroundColor: "transparent", // Light blue with transparency
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",

          }}
        >
          {/* row 1 */}
          <VuiBox
            style={{
              flex: 1,
              backgroundColor: "transparent", // Light blue with transparency
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: "space-between",
              padding: "0px",
            }}
          >
            <VuiBox style={{ backgroundColor: 'transparent', padding: '0px', width: isMobile ? "100%" : '50%', borderRadius: '15px' }}>
              <img style={{ width: '100%' }} src={proof9}             ></img>
            </VuiBox>

            <VuiBox style={{ backgroundColor: 'transparent', padding: '0px', width: isMobile ? "100%" : '50%', borderRadius: '15px' }}>
              <img style={{ width: '100%' }} src={proof8}             ></img>
            </VuiBox>

          </VuiBox>
          {/* row 1 end */}

          {/* row 2 */}
          <VuiBox
            style={{
              flex: 1,
              backgroundColor: "transparent", // Light blue with transparency
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: "space-between",
              padding: "0px",
            }}
          >
            <VuiBox style={{ backgroundColor: 'transparent', padding: '0px', width: isMobile ? "100%" : '50%', borderRadius: '15px' }}>
              <img style={{ width: '100%' }} src={proof3}             ></img>
            </VuiBox>

            <VuiBox style={{ backgroundColor: 'transparent', padding: '0px', width: isMobile ? "100%" : '50%', borderRadius: '15px' }}>
              <img style={{ width: '100%' }} src={proof4}             ></img>
            </VuiBox>

          </VuiBox>
          {/* row 2 end */}

          {/* row 3 */}
          <VuiBox
            style={{
              flex: 1,
              backgroundColor: "transparent", // Light blue with transparency
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: "space-between",
              padding: "0px",
            }}
          >
            <VuiBox style={{ backgroundColor: 'transparent', padding: '0px', width: isMobile ? "100%" : '50%', borderRadius: '15px' }}>
              <img style={{ width: '100%' }} src={proof5}             ></img>
            </VuiBox>

            <VuiBox style={{ backgroundColor: 'transparent', padding: '0px', width: isMobile ? "100%" : '50%', borderRadius: '15px' }}>
              <img style={{ width: '100%' }} src={proof10}             ></img>
            </VuiBox>

          </VuiBox>
          {/* row 3 end */}

        </VuiBox>
        {/* flippa end */}

        {/* CTA Section */}
        <VuiBox
          style={{
            marginTop: "100px",
            textAlign: "center",
            padding: "40px 20px",
            background: "white",
            width: '86.6%',
            borderRadius: borders.borderRadius.md,
          }}
        >
          <VuiTypography
            style={{
              fontSize: isMobile ? "5vw" : "2.4vw",
              fontWeight: "bold",
              color: "black",
              marginBottom: isMobile ? "35px" : "20px",
            }}
          >

            These Apps Can All Be Made With <b style={{ color: '#FC3837' }}> CodeGrow </b> In Under 7 Days
          </VuiTypography>
          <VuiTypography
            style={{
              fontSize: isMobile ? "4.5vw" : "2vw",
              fontWeight: "bold",
              color: "#FC3837",
              marginBottom: "20px",
            }}
          >

            So What Are You Waiting For?
          </VuiTypography>
          <VuiButton
            variant="contained"
            color="primary"
            size="large"
            onClick={() => navigate("/authentication/sign-up")}
            style={{
              backgroundColor: palette.info.main,
              color: "#fff",
              fontSize: isMobile ? "3.8vw" : "1.7vw", // Adjusts according to the viewport width
              padding: "15px 30px",
              marginTop: '20px'
            }}
          >
            Get Started Today
          </VuiButton>
        </VuiBox>
        {/* cta end */}



        {/* FAQ */}
        <VuiBox
          style={{
            marginTop: "100px",
            textAlign: "center",
            padding: "40px 20px",
            background: "#030515",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
          }}
        >
          <VuiTypography
            style={{
              fontSize: "48px",
              color: "#FC3837",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <b style={{ fontSize: isMobile ? "5.8vw" : "4.2vw", color: "white" }}>
              Frequently Asked Questions
            </b>
          </VuiTypography>

          {/* FAQ list */}
          <VuiBox
            style={{
              width: isMobile ? "90%" : "80%",
              maxWidth: "1200px",
              marginTop: "30px",
            }}
          >
            {faqData.map((faq, index) => (
              <VuiBox
                key={index}
                style={{
                  marginBottom: "20px",
                  padding: "20px",
                  background: "#1a1a2e",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                onClick={() => toggleQuestion(index)}
              >
                {/* Question */}
                <VuiTypography
                  style={{
                    color: "#FC3837",
                    fontSize: isMobile ? "4vw" : "1.5vw",
                  }}
                >
                  {faq.question}
                </VuiTypography>

                {/* Answer */}
                {openQuestion === index && (
                  <VuiTypography
                    style={{
                      marginTop: "10px",
                      fontSize: isMobile ? "4vw" : "1.2vw",
                      color: "white",
                      transition: "max-height 0.3s ease-in-out",
                      overflow: "hidden",
                    }}
                  >
                    {faq.answer}
                  </VuiTypography>
                )}
              </VuiBox>
            ))}
          </VuiBox>
        </VuiBox>
        {/* triple section end */}



      </VuiBox>


    </CoverLayout>
    </>
  );
}

export default SignIn;
