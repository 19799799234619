import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import VuiBox from "components/VuiBox";
import { useVisionUIController, setLayout } from "context";
import { useAuth } from "context/AuthContext";
import { Snackbar, Alert, Modal } from "@mui/material";
import axios from 'axios';
import PricingTable from '../../../components/PricingTable'; // Adjust the path as necessary
import { useMediaQuery } from '@mui/material';
import VuiButton from "components/VuiButton"; // Import Button if needed
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import dialog from "assets/theme/components/dialog";

import monthly from "assets/images/monthly.svg"; // Replace with your background image
import yearly from "assets/images/yearly.svg"; // Replace with your background image
import growth from "assets/images/growth.svg"; // Replace with your background image
import VuiTypography from "components/VuiTypography";
import { FaArrowRightLong } from "react-icons/fa6";
import { IoMdCheckmarkCircle } from "react-icons/io";
import { IoIosCloseCircle } from "react-icons/io";

function CoderLayout({ children }) {
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [isProUser, setIsProUser] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openBreakdownModal, setOpenBreakdownModal] = useState(false); // New state for package breakdown modal
  const isMobile = useMediaQuery('(max-width:600px)');
  const [isYearly, setIsYearly] = useState(true);

  const checkProAccount = async (userEmail) => {
    try {
      const response = await axios.post('https://checksubscription-wkmofyogxa-uc.a.run.app', { email: userEmail });
      setIsProUser(response.data.active);

      if (response.data.products.includes("prod_RcEJEcO8pMmfKw") || response.data.products.includes("prod_RcEJNYY5WtQO4U")) {
        console.log("User has the second subscription (prod_R4OaST7dmwUbVR)");
      }else  if (response.data.products.includes( "prod_R4OZRuMOJn2RXX") || response.data.products.includes( "prod_R4OaST7dmwUbVR")) {
        console.log("User has the hypergrowth subscription (prod_R4OaST7dmwUbVR)");
      }else{
        setOpenModal(true);
        console.log("User has theddd second subscription (prod_R4OaST7dmwUbVR)");


      }

      
    } catch (error) {
      console.error('Error checking subscription:', error);
      if (error.response && error.response.status === 404) {
        setOpenModal(true);
      }
      setIsProUser(false);
    }
  };

  useEffect(() => {
    if (currentUser) {
      checkProAccount(currentUser.email);
    }
  }, [currentUser]);

  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    setLayout(dispatch, "dashboard");
    if (!currentUser) {
      setOpenSnackbar(true);
      navigate("/authentication/sign-in");
    }
  }, [currentUser, dispatch, navigate]);

  const handleLogout = () => {
    setOpenModal(false);
    navigate("/authentication/logout");
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    navigate("/dashboard");
  };

  const handleUpgrade = () => {
    setOpenModal(false);
    navigate("/billing");
  };

  

  const handleViewPackageBreakdown = () => {
    setOpenBreakdownModal(true); // Open breakdown modal when the button is clicked
  };

  const handleCloseBreakdownModal = () => {
    setOpenBreakdownModal(false);
  };

  return (
    <>
      <VuiBox
        sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
          p: 3,
          position: "relative",
          minHeight: "calc(100vh - 0px)",
          [breakpoints.up("xl")]: {
            marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
            transition: transitions.create(["margin-left", "margin-right"], {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.standard,
            }),
          },
        })}
      >
        {children}
      </VuiBox>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity="warning">
          You need to log in to access this content.
        </Alert>
      </Snackbar>

      {/* Main Modal */}
      {openModal && (
        // <div style={{
        //   position: 'fixed',
        //   top: isMobile ? '60%' : '50%',
        //   left: '50%',
        //   transform: 'translate(-50%, -50%)',
        //   background: '#fff',
        //   padding: '20px',
        //   borderRadius: '8px',
        //   zIndex: 1000,
        //   boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        //   height: isMobile ? '80%' : '',
        //   width: isMobile ? '100%' : '',
        // }}>
        <Dialog
          open={openModal}
          onClose={handleCloseBreakdownModal}
          maxWidth={ isMobile? "100vw" : '50vw' }
          fullWidth
      
          PaperProps={{
            style: {  width: isMobile? "100vw" :'70vw', borderRadius:'15px',  padding: isMobile? "15px" : "30px",backgroundColor: 'white', color: 'black', height: isMobile? "75vh" :'80vh' }, // Customize modal background color
          }}
        >

          <button
            onClick={handleCloseModal}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'transparent',
              border: 'none',
              fontSize: '16px',
              cursor: 'pointer'
            }}
          >
            &#x2715; {/* Unicode for the 'X' symbol */}
          </button>
          <h2 style={{marginTop:'5px'}}>You don't have a Hypergrowth or Light Speed account</h2>
          <p>Upgrade to a Hypergrowth or Light Speed account to access this content.</p>
          <br/>
          <VuiButton style={{ color: 'blue' }} onClick={handleViewPackageBreakdown}>
            View Package Breakdown
          </VuiButton>
          <br/>
          <PricingTable /> {/* Render PricingTable inside the modal */}
          <VuiButton style={{ color: 'blue', marginTop: isMobile? '0px' : '50px'}} onClick={handleLogout}>
            Logout
          </VuiButton>
          </Dialog>

      )}

          {/* Package Breakdown Modal */}
          {openBreakdownModal && (
        <Dialog
          open={openModal}
          onClose={handleCloseBreakdownModal}
          maxWidth="80vw" // Adjust the width as needed
          fullWidth
          PaperProps={{
            style: { backgroundColor: '#333', color: 'white', height: '80vh' }, // Customize modal background color
          }}
        >

          <div style={{
            background: '#fff',
            padding: '20px',
            borderRadius: '8px',
            overflowY: 'auto',
            position: 'relative',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            marginTop: isMobile ? '0px' : '0px',
          }}>
            <button
              onClick={handleCloseBreakdownModal}
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                background: 'transparent',
                border: 'none',
                fontSize: '16px',
                cursor: 'pointer'
              }}
            >
              &#x2715; {/* Unicode for the 'X' symbol */}
            </button>
            <h2 style={{ color: 'black' }}>Package Breakdown</h2>
            <br />

            {/* YouTube video */}
            <div style={{ marginBottom: '20px' }}>
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/JaGNs45A_c8?si=mWIUqcRjCL_p1ReG" // Replace with your actual video link
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>

            {/* Table with package comparison */}
            {/* <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-evenly', marginBottom: '20px' }}>
                  <div>
                    <h2 style={{ color: 'black' }}>Monthly Package</h2>
                    <ul style={{ listStyleType: 'none', paddingLeft: 0, backgroundColor: '#FC3837', color: 'white', borderRadius: '10px', padding: '10px' }}>
                      {[
                         "AI Idea Finder",
                         "AI App Designer",
                         "AI Coder",
                         "AI Code Expander",
                         "Canva Design Templates",
                         "Flippa Sale Templates",
                         "AI Chrome Extensions",
                         "App Store Design Lab",
                      ].map((feature, index) => (
                        <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                          <span style={{ color: 'white', marginRight: '8px' }}>✔</span> {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <br />
                  <div>
                    <h2 style={{ color: 'black' }}>Yearly Package</h2>
                    <ul style={{ listStyleType: 'none', paddingLeft: 0, backgroundColor: '#FC3837', color: 'white', borderRadius: '10px', padding: '10px' }}>
                      {[
                        "Full Market Ready IOS Apps",
                         "AI Idea Finder",
                         "AI App Designer",
                         "AI Coder",
                         "AI Code Expander",
                         "Canva Design Templates",
                         "Flippa Sale Templates",
                         "AI Chrome Extensions",
                         "App Store Design Lab",
                      ].map((feature, index) => (
                        <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                          <span style={{ color: 'white', marginRight: '8px' }}>✔</span> {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div> */}
            <VuiBox style={{ display: 'flex', backgroundColor:'black' ,flexDirection: isMobile ? 'column' : "row" }}>

              {/* Pricing Bubble */}
             
              {/* basic */}
              <VuiBox
                display="flex"
                justifyContent="space-evenly"
                flexDirection="row"
                alignItems="center"
                width={isMobile ? "100%" : "100%"}
                margin="auto"
                padding="40px"
                style={{
                  background: "transparent",
                }}
                borderRadius="25px"
              >
                <VuiBox
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                  alignItems="center"
                  width={isMobile ? "100%" : "80%"}
                  padding="40px"
                  style={{
                    background: "white",
                  }}
                  borderRadius="25px"
                >
                  <VuiBox
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      flexDirection: "column",
                      width: "100%",
                      padding: isMobile ? "0px" : "0 0px",
                    }}
                  >
                    <img
                      src={isYearly ? monthly : monthly}
                      alt={isYearly ? "Basic Yearly Package" : "Basic Monthly Package"}
                      style={{ width: isMobile ? "20vw" : "5vw", height: "auto" }}
                    />
                    <br />
                    <VuiTypography style={{ fontSize: isMobile ? "5.8vw" : "2.3vw", fontWeight: "bold", color: "black" }}>
                      {isYearly ? "Hypergrowth" : "Hypergrowth"}
                    </VuiTypography>
                    <br />
                    <VuiTypography style={{ fontSize: isMobile ? "5.8vw" : "2.6vw", fontWeight: "bold", color: "black" }}>
                      {isYearly ? "$20/pm" : "$25/pm"}
                    </VuiTypography>
                  </VuiBox>
                  <br />
                  <center>
                    <VuiButton
                      variant="contained"
                      color="primary"
                      onClick={() => navigateToSignIn()}
                      style={{
                        backgroundColor: "transparent",
                        border: "2px solid #fe3737",
                        color: "#fe3737",
                        fontSize: isMobile ? "3.5vw" : "1.5vw",
                        padding: "10px 20px",
                        width: isMobile ? "100%" : "20vw",
                        borderRadius: "55px",
                      }}
                    >
                      Start For Free
                      <FaArrowRightLong style={{ marginLeft: "8px" }} />
                    </VuiButton>
                  </center>
                  <br />
                  <VuiTypography style={{ fontSize: isMobile ? "4vw" : "1.2vw", color: "black", marginTop: "10px" }}>
                    {[
                      "AI Idea Finder",
                      "AI App Designer",

                      "AI Chrome Extensions",
                      "App Store Design Lab",
                      "Private Discord Community",
                      "Canva Design & Flippa Sale Templates",
                      "Step By Step Course",
                      "AI Coder",
                    ].map((feature, index) =>
                      feature ? (
                        <li key={index} style={{ display: "flex", alignItems: "center", marginBottom: "10px", padding: "0px" }}>
                          <span style={{ color: "#fe3737", marginRight: "8px", }}>
                            <IoMdCheckmarkCircle style={{ marginRight: "10px", marginTop: '9px', fontSize: isMobile ? "4.5vw" : "2vw" }} />
                          </span>
                          {feature}
                        </li>
                      ) : null
                    )}

                    {[

                      "AI Code Expander",
                      "24/7 Support & 1:1 Coaching",
                      "Full Market Ready IOS Apps"
                    ].map((feature, index) =>
                      feature ? (
                        <li key={index} style={{ display: "flex", alignItems: "center", marginBottom: "10px", padding: "0px" }}>
                          <span style={{ color: "grey", marginRight: "8px" }}>
                            <IoIosCloseCircle style={{ marginRight: "10px", marginTop: '9px', fontSize: isMobile ? "4.5vw" : "2vw" }} />
                          </span>
                          {feature}
                        </li>
                      ) : null
                    )}

                  </VuiTypography>
                </VuiBox>
              </VuiBox>
              {/* basic end */}

              {/* pro */}
              <VuiBox
                display="flex"
                justifyContent="space-evenly"
                flexDirection="row"
                alignItems="center"
                width={isMobile ? "100%" : "100%"}
                margin="auto"
                padding="40px"
                style={{
                  background: "transparent",
                }}
                borderRadius="25px"
              >
                <VuiBox
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                  alignItems="center"
                  width={isMobile ? "100%" : "80%"}
                  padding="40px"
                  style={{
                    background: "white",
                  }}
                  borderRadius="25px"
                >
                  <VuiBox
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      flexDirection: "column",
                      width: "100%",
                      padding: isMobile ? "0px" : "0 0px",
                    }}
                  >
                    <img
                      src={isYearly ? yearly : yearly}
                      alt={isYearly ? "Pro Package" : "Pro Package"}
                      style={{ width: isMobile ? "20vw" : "5vw", height: "auto" }}
                    />
                    <br />
                    <VuiTypography style={{ fontSize: isMobile ? "5.8vw" : "2.3vw", fontWeight: "bold", color: "black" }}>
                      {isYearly ? "Light Speed" : "Light Speed"}
                    </VuiTypography>
                    <br />
                    <VuiTypography style={{ fontSize: isMobile ? "5.8vw" : "2.6vw", fontWeight: "bold", color: "black" }}>
                      {isYearly ? "$40/pm" : "$45/pm"}
                    </VuiTypography>
                  </VuiBox>
                  <br />
                  <center>
                    <VuiButton
                      variant="contained"
                      color="primary"
                      onClick={() => navigateToSignIn()}
                      style={{
                        backgroundColor: "transparent",
                        border: "2px solid #fe3737",
                        color: "#fe3737",
                        fontSize: isMobile ? "3.5vw" : "1.5vw",
                        padding: "10px 20px",
                        width: isMobile ? "100%" : "20vw",
                        borderRadius: "55px",
                      }}
                    >
                      Start For Free
                      <FaArrowRightLong style={{ marginLeft: "8px" }} />
                    </VuiButton>
                  </center>
                  <br />
                  <VuiTypography style={{ fontSize: isMobile ? "4vw" : "1.2vw", color: "black", marginTop: "10px" }}>
                    {[
                      "AI Idea Finder",
                      "AI App Designer",
                      "Canva Design & Flippa Sale Templates",
                      "AI Chrome Extensions",
                      "App Store Design Lab",
                      "Private Discord Community",
                      "Step By Step Course",
                      "AI Coder",
                      "AI Code Expander",
                      "24/7 Support & 1:1 Coaching",
                      "Full Market Ready IOS Apps"
                    ].map((feature, index) =>
                      feature ? (
                        <li key={index} style={{ display: "flex", alignItems: "center", marginBottom: "10px", padding: "0px" }}>
                          <span style={{ color: "#fe3737", marginRight: "8px", }}>
                            <IoMdCheckmarkCircle style={{ marginRight: "10px", marginTop: '9px', fontSize: isMobile ? "4.5vw" : "2vw" }} />
                          </span>
                          {feature}
                        </li>
                      ) : null
                    )}



                  </VuiTypography>
                </VuiBox>
              </VuiBox>
              {/* pro end */}
            </VuiBox>

            {/* List of features with checkmarks */}

          </div>
        </Dialog>
      )}
        </>
      );
}

CoderLayout.propTypes = {
        children: PropTypes.node.isRequired,
};

      export default CoderLayout;
