import React, { useState } from "react";
import { useMediaQuery } from "@mui/material"; // For responsive design
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import DashboardLayout from "examples/LayoutContainers/FreeLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import minicolourbg from "assets/images/minicolourbg.svg";
import { border, borderColor, width } from "@mui/system";
import { IoMdCheckmarkCircle } from "react-icons/io";
import { barChartOptionsDashboard } from "layouts/rtl/data/barChartOptions";

function Affiliate() {
  const isMobile = useMediaQuery("(max-width:600px)");

  // Remaining spots data
  const totalSpots = 25;
  const filledSpots = 12; // Example: 12 spots already taken
  const remainingSpots = totalSpots - filledSpots;
  const progressPercentage = (filledSpots / totalSpots) * 100;
  const checkmarkStyle = {
    marginRight: "10px",
    fontSize: isMobile ? " 35px " : "50px", // Uniform size
    color: "#fe3737",
    flexShrink: 0, // Prevent shrinking when space is limited
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        {/* Main Header Section */}
        <VuiBox

          width={isMobile ? "100%" : "100%"}
          // maxWidth="800px"
          // marginTop="5%"
          height={"isMobile ? 100vw : 85vw"}
          padding="40px"
          style={{
            backgroundImage: `url(${minicolourbg})`, // Background image for the landing page

            backgroundSize: "cover", // Ensures the image covers the entire area
            backgroundPosition: "center", // Centers the image
            backgroundRepeat: "no-repeat", // Prevents the image from repeating
          }}
          borderRadius="25px"
        >
          <VuiBox
            display="flex"
            flexDirection="column"

            justifyContent={isMobile ? "center" : "space-between"}
            alignItems="center"
            marginLeft={isMobile ? "0px" : '15%'}
            width={isMobile ? "100%" : "70%"}
            // maxWidth="800px"

            height=" 100%"
            padding="0px"

            borderRadius="25px"
          >


            <VuiTypography
              style={{
                textAlign: "center",
                fontSize: isMobile ? "6.5vw" : "3.8vw",
                fontWeight: "bold",
                color: "white",
                marginTop: "15px",
                lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here
              }}
            >
              Join Our <bold style={{
                color: "#FE3737",
              }}> Affiliate Programme</bold>
            </VuiTypography>
            <br />

            <VuiTypography
              style={{
                textAlign: "center",
                fontSize: isMobile ? "4.8vw" : "1.6vw",
                fontWeight: "bold",
                color: "#a1a1a1",
                marginBottom: isMobile ? "20px" : "0px",
                lineHeight: isMobile ? "1.1" : "1.2", // Adjust line spacing here
              }}
            >
              <bold style={{ color: 'white' }}>
                Join a community of creators revolutionizing app development. With tools designed for speed and simplicity,
              </bold>
              <span style={{ color: "#FE3737", marginLeft: "5px" }}>
                maximize your earning potential and transform ideas into success.
              </span>
            </VuiTypography>
            <br />

            <VuiButton
              aligncontent="center"
              variant="contained"
              color="primary"
              size="medium"
              onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLSfc-k566Pm_ZIQf0oFnYzeW1A77fKJhMNe5G6YiLGlEDXFXAw/viewform', '_blank')}
              style={{
                backgroundColor: '#1079FB',
                width: isMobile ? "50%" : "25%",
                color: " white",
                fontSize: isMobile ? "3.5vw" : "1.5vw",
                padding: "10px 20px",
                borderRadius: '55px',
                marginBottom: isMobile ? "20px" : "0px"
              }}
            >
              Join Today
            </VuiButton>


            <VuiBox mt={6} py={3} px={4} style={{ width: '100%', backgroundColor: '#00000090' }} border="3px solid #6c63ff" borderRadius="8px">
              <VuiTypography variant="h5" color="white" fontWeight="bold">
                Remaining Spots Left
              </VuiTypography>
              <VuiBox mt={2}>
                <VuiBox
                  height="25px"
                  borderRadius="5px"
                  sx={{ backgroundColor: "#2D2E5F", position: "relative" }}
                >
                  <VuiBox
                    width={`${progressPercentage}%`}
                    height="100%"
                    sx={{
                      backgroundColor: progressPercentage > 75 ? "#FE3737" : "#4CAF50",
                      borderRadius: "5px",
                    }}
                  />
                </VuiBox>
                <VuiTypography color="white" mt={2}>
                  <strong>{remainingSpots}</strong> spots left out of {totalSpots}.
                </VuiTypography>
              </VuiBox>
            </VuiBox>

            {/* Affiliate Benefits Section */}
            <VuiBox mt={6} py={3} px={4} style={{ width: '100%', backgroundColor: '#00000090' }} border="3px solid #6c63ff" borderRadius="8px">
              <VuiTypography style={{ fontSize: isMobile ? "6.5vw" : "2.2vw", }} color="white" fontWeight="bold">
                Benefits
              </VuiTypography>
              <VuiBox mt={2} >

                <VuiTypography
                  style={{
                    fontSize: "48px",
                    color: "#fe3737",
                    marginBottom: "30px",
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "start"
                  }}
                >
                  <IoMdCheckmarkCircle style={checkmarkStyle} />                  <b style={{ fontSize: isMobile ? "4.5vw" : "1.6vw", color: 'white' }}>Earn consistent income with a tiered commission structure.</b>
                </VuiTypography>

                <VuiTypography
                  style={{
                    fontSize: "48px",
                    color: "#fe3737",
                    marginBottom: "30px",
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "start"
                  }}
                >
                  <IoMdCheckmarkCircle style={checkmarkStyle} />                  <b style={{ fontSize: isMobile ? "4.5vw" : "1.6vw", color: 'white' }}>Gain access to exclusive tools and training for affiliates.</b>
                </VuiTypography>

                <VuiTypography
                  style={{
                    fontSize: "48px",
                    color: "#fe3737",
                    marginBottom: "30px",
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "start"
                  }}
                >
                  <IoMdCheckmarkCircle style={checkmarkStyle} />                  <b style={{ fontSize: isMobile ? "4.5vw" : "1.6vw", color: 'white' }}>Monthly bonuses for top-performing affiliates.</b>
                </VuiTypography>


              </VuiBox>
            </VuiBox>

            {/* Earning Potential Section  */}
            <VuiBox mt={6} py={3} px={4} style={{ width: '100%', backgroundColor: '#00000090' }} border="3px solid #6c63ff" borderRadius="8px">
              <VuiTypography style={{ fontSize: isMobile ? "6.5vw" : "2.2vw", }} color="white" fontWeight="bold">
                Earning Potential

              </VuiTypography>
              <VuiBox mt={2} >

                <VuiTypography
                  style={{
                    fontSize: "48px",
                    color: "#fe3737",
                    marginBottom: "30px",
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "start"
                  }}
                >
                  <IoMdCheckmarkCircle style={checkmarkStyle} />                  <b style={{ fontSize: isMobile ? "4.5vw" : "1.6vw", color: 'white' }}>Base commission is 20% on every sale.</b>
                </VuiTypography>

                <VuiTypography
                  style={{
                    fontSize: "48px",
                    color: "#fe3737",
                    marginBottom: "30px",
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "start"
                  }}
                >
                  <IoMdCheckmarkCircle style={checkmarkStyle} />                  <b style={{ fontSize: isMobile ? "4.5vw" : "1.6vw", color: 'white' }}>For monthly plans, earn $5 per subscription.</b>
                </VuiTypography>

                <VuiTypography
                  style={{
                    fontSize: "48px",
                    color: "#fe3737",
                    marginBottom: "30px",
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "start"
                  }}
                >
                  <IoMdCheckmarkCircle style={checkmarkStyle} />                  <b style={{ fontSize: isMobile ? "4.5vw" : "1.6vw", color: 'white' }}>For yearly plans, earn $50 per subscription.</b>
                </VuiTypography>

                <VuiTypography
                  style={{
                    fontSize: "48px",
                    color: "#fe3737",
                    marginBottom: "30px",
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "start"
                  }}
                >
                  <IoMdCheckmarkCircle style={checkmarkStyle} />                  <b style={{ fontSize: isMobile ? "4.5vw" : "1.6vw", color: 'white' }}>Higher commissions are unlocked based on performance.</b>
                </VuiTypography>

                <VuiTypography
                  style={{
                    fontSize: "48px",
                    color: "#fe3737",
                    marginBottom: "30px",
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "start"
                  }}
                >
                  <IoMdCheckmarkCircle style={checkmarkStyle} />                  <b style={{ fontSize: isMobile ? "4.5vw" : "1.6vw", color: 'white' }}>Achieve $500 in sales per month to earn an additional 5% (total 25% commission).</b>
                </VuiTypography>

                <VuiTypography
                  style={{
                    fontSize: "48px",
                    color: "#fe3737",
                    marginBottom: "30px",
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "start"
                  }}
                >
                  <IoMdCheckmarkCircle style={checkmarkStyle} />                  <b style={{ fontSize: isMobile ? "4.5vw" : "1.6vw", color: 'white' }}>	Reach $1,000 in sales per month to earn an additional 10% (total 30% commission).</b>
                </VuiTypography>


              </VuiBox>
            </VuiBox>

            {/* Advertising Methods Section */}
            <VuiBox mt={6} py={3} px={4} style={{ width: '100%', backgroundColor: '#00000090' }} border="3px solid #6c63ff" borderRadius="8px">
              <VuiTypography style={{ fontSize: isMobile ? "6.5vw" : "2.2vw", }} color="white" fontWeight="bold">
                How to Advertise

              </VuiTypography>
              <VuiBox mt={2} >

                <VuiTypography
                  style={{
                    fontSize: "48px",
                    color: "#fe3737",
                    marginBottom: "30px",
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "start"
                  }}
                >
                  <IoMdCheckmarkCircle style={checkmarkStyle} />                  <b style={{ fontSize: isMobile ? "4.5vw" : "1.6vw", color: 'white' }}>Paid Advertising involves using platforms like Google Ads and Facebook Ads to attract targeted traffic and boost conversions.</b>
                </VuiTypography>

                <VuiTypography
                  style={{
                    fontSize: "48px",
                    color: "#fe3737",
                    marginBottom: "30px",
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "start"
                  }}
                >
                  <IoMdCheckmarkCircle style={checkmarkStyle} />                  <b style={{ fontSize: isMobile ? "4.5vw" : "1.6vw", color: 'white' }}>Organic Growth focuses on sharing your journey through social media, engaging blog posts, and authentic content to naturally build trust and grow your audience.</b>
                </VuiTypography>


              </VuiBox>
            </VuiBox>


            <VuiButton
              aligncontent="center"
              variant="contained"
              // color="primary"
              size="medium"
              onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLSfc-k566Pm_ZIQf0oFnYzeW1A77fKJhMNe5G6YiLGlEDXFXAw/viewform', '_blank')}
              style={{
                backgroundColor: "#fe3737",
                width: isMobile ? "50%" : "25%",
                color: " white",
                fontSize: isMobile ? "3.5vw" : "1.5vw",
                padding: "10px 20px",
                borderRadius: '55px',
                marginTop: isMobile ? "20px" : "40px",
                marginBottom: isMobile ? "20px" : "0px",
                border: "0px solid white"
              }}
            >
              Join Today
            </VuiButton>
          </VuiBox>
        </VuiBox>






      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Affiliate;