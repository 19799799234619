/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { Card, LinearProgress, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiProgress from "components/VuiProgress";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import FreeLayout from "examples/LayoutContainers/FreeLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import linearGradient from "assets/theme/functions/linearGradient";

// Vision UI Dashboard React base styles
import typography from "assets/theme/base/typography";
import colors from "assets/theme/base/colors";

// Dashboard layout components
import WelcomeMark from "layouts/dashboard/components/WelcomeMark";
import Projects from "layouts/dashboard/components/Projects";
import OrderOverview from "layouts/dashboard/components/OrderOverview";
import SatisfactionRate from "layouts/dashboard/components/SatisfactionRate";
import ReferralTracking from "layouts/dashboard/components/ReferralTracking";

// React icons
import { IoIosRocket } from "react-icons/io";
import { IoGlobe } from "react-icons/io5";
import { IoBuild } from "react-icons/io5";
import { IoWallet } from "react-icons/io5";
import { IoDocumentText } from "react-icons/io5";
import { FaShoppingCart } from "react-icons/fa";
import { IoListCircleSharp } from "react-icons/io5";

// Data
import LineChart from "examples/Charts/LineCharts/LineChart";
import BarChart from "examples/Charts/BarCharts/BarChart";
// import { lineChartDataDashboard } from "layouts/dashboard/data/lineChartData";
import { lineChartOptionsDashboard } from "layouts/dashboard/data/lineChartOptions";
import { barChartDataDashboard } from "layouts/dashboard/data/barChartData";
import { barChartOptionsDashboard } from "layouts/dashboard/data/barChartOptions";
import { getFirestore, doc, getDoc, getDocs } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { Button } from "@mui/material"; // Importing the Button component

import React, { useEffect, useState } from "react";

function Dashboard() {
  const { gradients } = colors;
  const { cardContent } = gradients;
  const [exportableData, setExportableData] = useState([{ name: "Loading...", data: [0] }]);
  const db = getFirestore();
  const auth = getAuth();
  const [updateCount, setUpdateCount] = useState(0);

  const [currentMonthRevenue, setCurrentMonthRevenue] = useState(0);
  const [previousMonthRevenue, setPreviousMonthRevenue] = useState(0);
  const [percentageChange, setPercentageChange] = useState(0);
  const [previousMonthName, setPreviousMonthName] = useState('');
  const [activeUsersTotal, setActiveUsersTotal] = useState('');

  // Inside your fetchProjects function, after calculating monthlyRevenueValues
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth(); // 0 = January, 11 = December
  const previousMonth = (currentMonth === 0) ? 11 : currentMonth - 1; // Wrap around for January

  let totalCurrentMonthRevenue = 0;
  let totalPreviousMonthRevenue = 0;

  const [loading, setLoading] = useState(true);


  const [totalPrice, setTotalPrice] = useState(0);
  const [loadingPrice, setLoadingPrice] = useState(true);
  const [appsData, setAppsData] = useState([]);

  // Function to fetch the total active users
  const fetchTotalPrice = async () => {
    setLoadingPrice(true);
    let totalPriceCount = 0;
    const user = auth.currentUser;

    try {
      const userDocRef = doc(db, "users", user.uid);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data().liveProjects || [];
        console.log(userData);

        // Sum the activeUsers for each project, ensuring it's treated as a number
        userData.forEach((project) => {
          totalPriceCount += Number(project.listingPrice) || 0; // Ensure activeUsers is converted to a number
        });
      }

      setTotalPrice(totalPriceCount);
    } catch (error) {
      console.error("Error fetching total price:", error);
    } finally {
      setLoadingPrice(false);
    }
  };
  // Fetch total users on component mount
  useEffect(() => {
    fetchTotalPrice();
  }, []);

  const [totalUsers, setTotalUsers] = useState(0);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [barChartDataDashboard, setBarChartDataDashboard] = useState([]);

  // Function to fetch the total active users
  const fetchTotalUsers = async () => {
    setLoadingUsers(true);
    let totalUsersCount = 0;
    let apps = [];

    const user = auth.currentUser;

    try {
      const userDocRef = doc(db, "users", user.uid);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data().liveProjects || [];

        // Calculate total users and breakdown for each app
        userData.forEach((project) => {
          const activeUsers = Number(project.activeUsers) || 0;
          totalUsersCount += activeUsers;
          apps.push({
            name: project.name, // App name
            activeUsers,
            percentage: 0, // We'll calculate percentage next
          });
        });

        // Calculate the percentage breakdown for each app
        apps = apps.map((app) => ({
          ...app,
          percentage: ((app.activeUsers / totalUsersCount) * 100).toFixed(2),
        }));

        // Prepare bar chart data
        const chartData = {
          labels: apps.map((app) => app.name), // App names for X-axis
          datasets: [
            {
              label: "Active Users",
              data: apps.map((app) => app.activeUsers), // Active users per app
              backgroundColor: "#42A5F5", // Customize color
            },
          ],
        };

        // Set state for bar chart and other data
        setBarChartDataDashboard(chartData);
        setAppsData(apps);
        setTotalUsers(totalUsersCount);
      }
    } catch (error) {
      console.error("Error fetching total users:", error);
    } finally {
      setLoadingUsers(false);
    }
  };

  // Fetch total users on component mount
  useEffect(() => {
    fetchTotalUsers();
  }, []);


  const fetchProjects = async () => {
    setLoading(true);
    try {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data().liveProjects || [];
          const formattedData = userData.map((project, index) => {
            const monthlyRevenueValues = new Array(12).fill(0);
            project.revenueHistory.forEach((entry) => {
              const date = new Date(entry.date);
              const month = date.getMonth();
              const amount = entry.amount;
              if (month === currentMonth) {
                totalCurrentMonthRevenue += amount;
              } else if (month === previousMonth) {
                totalPreviousMonthRevenue += amount;
              }
              monthlyRevenueValues[month] += entry.amount;
            });

            setCurrentMonthRevenue(totalCurrentMonthRevenue);
            setPreviousMonthRevenue(totalPreviousMonthRevenue);
            setPreviousMonthName(currentDate.toLocaleString('default', { month: 'long', year: 'numeric' }).replace(new RegExp(`^${new Date().toLocaleString('default', { month: 'long' })}`), previousMonth));

            return {
              name: project.name || `Project ${index + 1}`,
              data: monthlyRevenueValues,
            };
          });

          setExportableData(formattedData);



        }
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);
  useEffect(() => {
    if (previousMonthRevenue > 0) {
      const change = ((currentMonthRevenue - previousMonthRevenue) / previousMonthRevenue) * 100;
      setPercentageChange(change);
    } else {
      setPercentageChange(currentMonthRevenue > 0 ? 100 : 0); // Handle case when previous month revenue is 0
    }
  }, [currentMonthRevenue, previousMonthRevenue]);



  // Initial data load
  useEffect(() => {
    fetchProjects();
  }, []); // Runs onl // Empty array, runs only on mount



  return (
    <FreeLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <VuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} xl={3}>
            <MiniStatisticsCard
  title={{ text: "This Month's Revenue", fontWeight: "regular" }}
  count={"$" + currentMonthRevenue}
  percentage={{
    color: percentageChange >= 0 ? "success" : "error",
    text: (
      <Typography 
        variant="caption" 
        style={{ fontSize: "0.8rem", verticalAlign: "middle" }}
      >
        {`${percentageChange >= 0 ? '+' : '-'}${Math.abs(percentageChange).toFixed(2)}%`}
      </Typography>
    ),
  }}
  icon={{
    color: "info",
    component: <IoWallet color="white" size="22px" style={{ marginBottom: '10px' }} />,
  }}
/>

            </Grid>

            <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Last Month's Revenue", fontWeight: "regular" }}
                count={"$" + previousMonthRevenue}
                percentage={{
                  color: (percentageChange * -1) >= 0 ? "success" : "error",
                  text: (
                    <span>
                      <Typography
                        variant="caption"
                        style={{ fontSize: "0.8rem", verticalAlign: "middle" }}
                      >
                        {`${(percentageChange * -1) >= 0 ? '+' : '-'}${Math.abs(percentageChange).toFixed(2)}%`}
                      </Typography>
                    </span>
                  ),
                }}
                icon={{
                  color: "info",
                  component: (
                    <IoWallet size="22px" style={{ marginBottom: "10px" }} color="white" />
                  ),
                }}
              />
            </Grid>


            <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Total Users" }}
                count={loadingUsers ? "Loading..." : totalUsers}
                // percentage={{ color: "success", text: "+5%" }}
                icon={{ color: "info", component: <IoGlobe size="22px" style={{ marginBottom: '10px' }} color="white" /> }}
              />
            </Grid>

            <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Potential Sale Revenue" }}
                count={loadingUsers ? "Loading..." : "$" + totalPrice}
                // percentage={{ color: "success", text: "+5%" }}
                icon={{ color: "info", component: <IoListCircleSharp size="22px" style={{ marginBottom: '10px' }} color="white" /> }}
              />
            </Grid>



          </Grid>
        </VuiBox>

        <VuiBox mb={3}>
          <Grid container spacing="18px">
            <Grid item xs={12} lg={12} xl={5}>
              <WelcomeMark />
            </Grid>

          </Grid>
        </VuiBox>




        {/* row 1 */}
        <VuiBox mb={3}>
          <Grid container spacing={3} direction="row" justifyContent="center" alignItems="stretch">

            <Grid item xs={12} md={6} lg={6}>
              <Card style={{ minHeight: '100%', }}>
                <VuiBox sx={{ height: "100%" }}>
                  <VuiBox display="flex" alignItems="start" direction="row" justifyContent="space-between" mb={3}>
                    <div>
                      <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px" sx={{ marginRight: '16px' }}>
                        Sales Overview
                      </VuiTypography>
                      <VuiBox display="flex" alignItems="center" mb="40px">
                        <VuiTypography variant="button" color={percentageChange >= 0 ? "success" : "error"} fontWeight="bold">
                          {percentageChange.toFixed(2)}% {percentageChange >= 0 ? "more" : "less"} than {previousMonthName}

                        </VuiTypography>
                      </VuiBox>
                    </div>

                    <Button
                      style={{ color: 'white' }}
                      variant="contained"
                      color="primary"
                      onClick={fetchProjects} // This triggers the data fetch
                    >
                      Refresh Data
                    </Button>
                  </VuiBox>


                  <VuiBox sx={{ height: "310px" }}>
                    {loading ? (
                      <VuiTypography variant="button" color="text" fontWeight="medium">
                        Loading data...
                      </VuiTypography>
                    ) : (
                      <LineChart
                        lineChartData={exportableData.length > 0 ? exportableData : [{ name: "No Data", data: [0] }]}
                        lineChartOptions={lineChartOptionsDashboard}
                      />
                    )}
                  </VuiBox>

                </VuiBox>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Projects />
            </Grid>

          </Grid>
        </VuiBox>
        {/* row 1 end */}

        {/* row 2 */}
        <VuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
              <OrderOverview />
            </Grid>


            <Grid item xs={12} md={6} lg={6}>
              <Card style={{ minHeight: '450px', maxHeight: '450px', overflowY: 'auto' }}>
                <VuiBox>
                  {/* Header - left-aligned */}
                  <VuiTypography style={{ fontSize: '23px' }} color="white" fontWeight="bold" mb="5px">
                    Active Users
                  </VuiTypography>

                  {/* Table Structure */}
                  <table style={{ width: '100%', color: 'white', borderCollapse: 'collapse' }}>
                    <thead>
                      <tr style={{ borderBottom: '1px solid #818589' }}>
                        <th style={{ textAlign: 'left', padding: '10px', fontSize: '18px' }}>App Name</th>
                        <th style={{ textAlign: 'center', padding: '10px', fontSize: '18px' }}>Active Users</th>
                        <th style={{ textAlign: 'center', padding: '10px', fontSize: '18px' }}>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {appsData.map((app) => (
                        <tr key={app.name} style={{ borderBottom: '1px solid #818589' }}>
                          <td style={{ padding: '10px', textAlign: 'left', width: '40%' }}>
                            <VuiTypography style={{ color: 'white' }} variant="button" fontWeight="medium">
                              {app.name}
                            </VuiTypography>
                          </td>
                          <td style={{ padding: '10px', textAlign: 'center', width: '30%' }}>
                            <VuiTypography style={{ color: 'white' }} variant="lg" fontWeight="bold">
                              {app.activeUsers}
                            </VuiTypography>
                          </td>
                          <td style={{ padding: '10px', textAlign: 'center', width: '30%' }}>
                            <VuiProgress value={app.percentage} color="info" sx={{ background: "#2D2E5F" }} />

                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </VuiBox>
              </Card>
            </Grid>

          </Grid>
        </VuiBox>
        {/* row 2 end */}

        {/* row 3 */}
        <VuiBox mb={3}>
          <Grid container spacing="18px">
            <Grid item xs={12} lg={12} xl={5}>
              <SatisfactionRate />
            </Grid>
            {/* <Grid item xs={12} lg={6} xl={4}>
              <ReferralTracking />
            </Grid> */}
          </Grid>
        </VuiBox>
        {/* row 3 end */}

      </VuiBox>
      <Footer />
    </FreeLayout>
  );
}

export default Dashboard;
