import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import CoverLayout from "layouts/authentication/components/BasicLayout";
import rgba from "assets/theme/functions/rgba";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import { IoMdCheckmarkCircle } from "react-icons/io";
import RcSlider from 'rc-slider'; // Correct import statement
import 'rc-slider/assets/index.css'; // Import the default styles
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useMediaQuery } from '@mui/material'; // Import useMediaQuery
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // carousel styles
import bgDash4 from "assets/images/bg4.svg"; // Replace with your background image
import longlogoblack from "assets/images/codegrowlong-black.png"; // Replace with your background image
import { FaArrowRightLong } from "react-icons/fa6";
import box2 from "assets/images/box2.png"; // Replace with your background imageminicolourbg.svg
import minicolourbg from "assets/images/minicolourbg.svg"; // Replace with your background image
import redbox1 from "assets/images/redbox1.png"; // Replace with your background image
import redbox2 from "assets/images/redbox2.png"; // Replace with your background image
import ai1 from "assets/images/ai1.png"; // Replace with your background image
import ai2 from "assets/images/ai2.png"; // Replace with your background image
import ai3 from "assets/images/ai3.png"; // Replace with your background image
import macbook from "assets/images/macbook.png"; // Replace with your background image
import monthly from "assets/images/monthly.svg"; // Replace with your background image
import yearly from "assets/images/yearly.svg"; // Replace with your background image
import { fontGrid } from "@mui/material/styles/cssUtils";

function SignIn() {
  const navigate = useNavigate();
  const [appsPerMonth, setAppsPerMonth] = useState(2);
  const [dailyRevenue, setDailyRevenue] = useState(10);
  const [flippaSalePrice, setFlippaSalePrice] = useState(1500);
  const monthlyRevenuePerApp = dailyRevenue * 30; // 30 days in a month
  const yearlyRevenuePerApp = monthlyRevenuePerApp * 12;
  const totalRevenuePerMonth = (appsPerMonth * 12) * monthlyRevenuePerApp;
  const totalRevenuePerYear = totalRevenuePerMonth * 12;
  const totalFlippaRevenue = (appsPerMonth * 12) * flippaSalePrice;
  const totalCombinedRevenue = totalRevenuePerYear + totalFlippaRevenue;
  const totalCombinedMonthly = totalCombinedRevenue / 12;
  const isMobile = useMediaQuery('(max-width:600px)'); // Adjust breakpoint as needed
  const [openQuestion, setOpenQuestion] = useState(null);
  const [isYearly, setIsYearly] = useState(true);

  const toggleQuestion = (index) => {
    setOpenQuestion(openQuestion === index ? null : index);
  };

  const faqData = [
    {
      question: "What is included in the CodeGrow subscription?",
      answer:
        "The subscription includes unlimited access to our AI-powered app development tools, fully customizable app templates, video tutorials, pre-built Firebase and AdMob integrations, and more."
    },
    {
      question: "What AI features are included?",
      answer:
        "CodeGrow's AI tools can generate app designs, suggest code improvements, debug issues, and even create fully functional components to save you time and effort."
    },
    {
      question: "How many apps can I create with CodeGrow?",
      answer:
        "You can create an unlimited number of apps with your subscription. There's no cap on creativity or output!"
    },
    {
      question: "What programming technologies are used in these apps?",
      answer:
        "CodeGrow apps are built with Flutter for cross-platform compatibility and leverage Firebase for back-end services. SQLite is also available for local storage."
    },
    {
      question: "Are there templates available for specific types of apps?",
      answer:
        "Yes, CodeGrow provides a variety of templates for apps such as games, productivity tools, social media integrations, and more. All templates are fully customizable."
    },
    {
      question: "Can I access CodeGrow on multiple devices?",
      answer:
        "Absolutely! CodeGrow is fully responsive and works seamlessly on desktops, tablets, and smartphones, allowing you to build apps from anywhere."
    },
    {
      question: "How do I get started with CodeGrow?",
      answer:
        "Once you sign up, you'll receive immediate access to the platform. Start by exploring the tutorials and templates, and you'll be creating your first app in no time."
    },
    {
      question: "Can I cancel my subscription at any time?",
      answer:
        "Yes, you can cancel your subscription anytime by logging into your account and navigating to the billing section."
    },
    {
      question: "Does CodeGrow offer real-time support?",
      answer:
        "Yes, our support team is available to assist you with any questions or issues you may encounter while using CodeGrow."
    }
  ];


  function navigateToHome() {

    // Navigation logic
    navigate("/authentication/home");
  }

  function navigateToSignIn() {
    // TikTok PII Identification (if applicable)
    ttq.identify({
      "email": "<hashed_email_address>", // Replace with SHA-256 hashed email
      "phone_number": "<hashed_phone_number>", // Replace with SHA-256 hashed phone number
      "external_id": "<hashed_external_id>" // Replace with SHA-256 hashed external ID
    });

    // TikTok Event Tracking
    ttq.track('ViewContent', {
      page_section: "Sign-In Page"
    });

    // Navigation logic
    navigate("/authentication/sign-in");
  }

  return (
    <><CoverLayout >

      {/* top section start */}
      <VuiBox
        style={{
          width: '100vw',
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
          backgroundColor: "white",
          minHeight: "100vh",
          marginBottom: '-20px',
          backgroundImage: `url(${bgDash4})`, // Background image for the landing page
          backgroundSize: "cover", // Ensures the image covers the entire area
          backgroundPosition: "center", // Centers the image
          backgroundRepeat: "no-repeat", // Prevents the image from repeating
          padding: "0", // Remove padding to avoid affecting width
        }}
      >

        {/* top menu*/}
        <VuiBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width={isMobile ? "100%" : "90%"}
          // maxWidth="800px"
          marginTop="0px"
          padding="20px"

          borderRadius={borders.borderRadius.md}
        >
          {/* Logo on the Left */}
          <VuiBox display="flex" alignItems="center">
            <img
              src={longlogoblack} // Remove the `${}`
              alt="Logo"
              style={{ height: isMobile ? "17vw" : "10vw", marginBottom: '-20px' }}
            />

          </VuiBox>

          {/* Buttons on the Right */}
          <VuiBox display="flex" gap="20px" style={{ marginBottom: '-20px' }}
          >
            <VuiButton
              variant="contained"
              color="primary"
              size="medium"
              onClick={() => navigateToSignIn()}
              style={{
                backgroundColor: "transparent",
                color: "#fff",
                fontSize: isMobile ? "3.5vw" : "1.5vw",
                padding: "10px 20px",
                borderRadius: '55px'
              }}
            >
              Log in
            </VuiButton>

            <VuiButton
              variant="contained"
              color="primary"
              size="medium"
              onClick={() => navigateToSignIn()}
              style={{
                backgroundColor: "#404040",
                color: "#fff",
                fontSize: isMobile ? "3.5vw" : "1.5vw",
                padding: "10px 20px",
                borderRadius: '55px',
                display: isMobile ? "none" : "block",
              }}
            >
              Get Started
            </VuiButton>
            <VuiButton
              variant="contained"
              color="primary"
              size="medium"
              onClick={navigateToHome}
              style={{
                backgroundColor: "white",
                color: "#fe3737",
                fontSize: isMobile ? "3.5vw" : "1.5vw",
                padding: "10px 20px",
                borderRadius: '55px'
              }}
            >
              See Demo
            </VuiButton>

          </VuiBox>
        </VuiBox>
        {/* top menu end */}

        {/* triple section menu*/}
        <VuiBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"

          style={{
            width: isMobile ? "90%" : "60%"
          }}
          // maxWidth="800px"
          marginTop="0px"
          padding="20px"
          background={rgba(palette.info.main, 0.8)}
          borderRadius={borders.borderRadius.md}
          flexDirection={isMobile ? "row" : "row"}
        >


          <VuiBox
            component="img"
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnAw71DBcX3N0AnwvHgAsr-HIIDsfgR2JpKw&s"
            alt="Source Code Ready"
            sx={{
              width: isMobile ? "25vw" : "20vw", // Adjust the size based on the device
              height: isMobile ? "25vw" : "20vw", // Make it a square
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "30px",
              borderRadius: "4vw", // Optional: Add rounded corners
            }}
          />
          <VuiTypography
            style={{
              fontSize: isMobile ? "7.5vw" : "5.5vw", // Adjusts according to the viewport width
              fontWeight: "bold",
              color: "white",
              marginBottom: isMobile ? "20px" : "-20px",
              textAlign: "center", // Centers the text
            }}
          >
            VS
          </VuiTypography>

          <VuiBox
            component="img"
            src="https://yt3.googleusercontent.com/FT4vswRQeMryE9xKfGA39vHdQz44nkZgLL2dxHCfjJXrzyWx_lZ8goApgSkz2KSZ1V2w_5GxYQ=s900-c-k-c0x00ffffff-no-rj"
            alt="Source Code Ready"
            sx={{
              width: isMobile ? "25vw" : "20vw", // Adjust the size based on the device
              height: isMobile ? "25vw" : "20vw", // Make it a square
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "30px",
              borderRadius: "4vw", // Optional: Add rounded corners
            }}
          />




        </VuiBox>
        {/* triple section end */}


        <VuiBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          width={isMobile ? "100%" : "80%"}
          // maxWidth="800px"
          marginTop="0px"
          padding="20px"


          borderRadius={borders.borderRadius.md}
        >




          <VuiTypography
            style={{
              component: "h1",
              fontSize: isMobile ? "7.5vw" : "6.5vw", // Adjusts according to the viewport width
              fontWeight: "bold",
              color: "white",
              marginBottom: isMobile ? "0px" : "-10px",
              textAlign: "center", // Centers the text
            }}
          >
            Adalo vs Bravo Studio

          </VuiTypography>
          <VuiTypography
            style={{
              component: "h2",
              fontSize: isMobile ? "7.5vw" : "3.5vw", // Adjusts according to the viewport width
              fontWeight: "bold",
              color: "#fe3737",
              marginBottom: "15px",
              textAlign: "center", // Centers the text
            }}
          >
            Simplifying App Creation While Retaining Ownership of Your Code

          </VuiTypography>

          <VuiBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            width={isMobile ? "100%" : "85%"}
            // maxWidth="800px"
            marginTop="0px"
            padding="20px"
            marginBottom="100px"
            borderRadius={borders.borderRadius.md}
          >

            <VuiTypography style={{ component: "description", textAlign: "center", fontSize: isMobile ? "4.5vw" : "1.5vw", fontWeight: "bold", color: "white", marginBottom: "20px" }}>
              As two of the most popular no-code platforms, Adalo and Bravo Studio empower users to create mobile apps without writing a single line of code. Whether you’re a business owner seeking a cost-effective solution or a developer wanting to retain ownership of your source code, these tools offer unique benefits. In this comparison, we’ll dive into their user-friendliness, supported platforms, integrations, and learning curve to help you decide which no-code app builder is right for your next project.
            </VuiTypography>
          </VuiBox>



        </VuiBox>

      </VuiBox>
      {/* top section end */}

      {/* second section start */}
      <VuiBox
        style={{
          width: '100vw',
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
          background: "white", // Fading effect

          marginTop: '0px',

          padding: "0", // Remove padding to avoid affecting width
        }}
      >


        {/* top menu*/}
        <VuiBox
          display="flex"
          justifyContent="space-between"
          flexDirection={isMobile ? "column" : "row"}
          alignItems={isMobile ? "start" : "center"}
          width={isMobile ? "100%" : "90%"}
          // maxWidth="800px"
          marginTop="-70px"
          padding="20px"
          style={{
            background: "linear-gradient(to top, white 60%, transparent 100%)", // Fading effect
          }}
          borderRadius={borders.borderRadius.md}
        >
          <VuiBox

            width={isMobile ? "100%" : "48%"}
          >
            <VuiTypography style={{ textAlign: "start", fontSize: isMobile ? "4.5vw" : "2.8vw", fontWeight: "bold", color: "black", marginBottom: "20px" }}>
              AI-Driven Tools <bold style={{ color: '#69696a' }}> Powering App Store Success</bold>
            </VuiTypography>
          </VuiBox>

          {/* Buttons on the Right */}
          <VuiBox display="flex" gap="20px" style={{ marginBottom: '-20px' }}
          >

            <VuiButton
              variant="contained"
              color="primary"
              size="medium"
              onClick={() => navigateToSignIn()}
              style={{
                backgroundColor: 'transparent',
                border: " 2px solid #1079fb",
                color: " #1079fb",
                fontSize: isMobile ? "3.5vw" : "1.5vw",
                padding: "10px 20px",
                borderRadius: '55px'
              }}
            >
              Get Started
              <FaArrowRightLong
                style={{ marginLeft: "8px" }}

              />  </VuiButton>

          </VuiBox>
        </VuiBox>
        {/* top menu end */}
      </VuiBox>

      {/* second section end */}

      {/* third section start */}
      <VuiBox
        style={{
          width: '100vw',
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
          background: "linear-gradient(to top, #F7F8FB 95%, white 100%)", // Fading effect
          minHeight: "100vh",
          marginTop: '0px',

          padding: "0", // Remove padding to avoid affecting width
        }}
      >



        {/* section one start */}
        <VuiBox
          display="flex"
          justifyContent="space-between"
          flexDirection={isMobile ? "column" : "column"}
          // alignItems="start"
          width={isMobile ? "100%" : "90%"}
          marginTop="5%"
          padding="40px"
          style={{
            background: "white",
          }}
          borderRadius="25px"
        >
          <VuiTypography
            style={{
              component: "h1",
              textAlign: "center",
              fontSize: isMobile ? "6.5vw" : "3.2vw",
              fontWeight: "bold",
              color: "#fe3737",
              marginBottom: "50px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            A Quick Overview of Adalo and Bravo Studio


          </VuiTypography>

          {/* what is bubble start */}
          <VuiTypography
            style={{
              component: "h1",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "2vw",
              fontWeight: "bold",
              color: "#69696a",
              marginBottom: "10px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            <bold style={{ color: "black" }}>What is Adalo?</bold>
          </VuiTypography>

          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              // fontWeight: "bold",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            Adalo is a no-code app builder designed to help individuals and businesses create fully functional mobile and web apps with ease. Its drag-and-drop interface and built-in database functionality allow users to design and launch apps quickly, even without technical expertise. Adalo also provides access to the app’s source code, enabling advanced customization and scalability.
          </VuiTypography>

          <br />

          <VuiTypography
            style={{
              component: "h1",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.8vw",
              fontWeight: "bold",
              color: "#69696a",
              marginBottom: "10px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            <bold style={{ color: "black" }}>Key Features</bold>
          </VuiTypography>

          <VuiTypography
            style={{
              component: "h3",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              // fontWeight: "bold",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            <li>

              Drag-and-drop editor for easy app design



            </li>
            <li>

              Built-in database and workflow functionality
            </li>
            <li>

              Option to export source code for further development

            </li>

          </VuiTypography>
          {/* what is bubble end */}

          <br />

          {/* what is flutterflow start */}
          <VuiTypography
            style={{
              component: "h1",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "2vw",
              fontWeight: "bold",
              color: "#69696a",
              marginBottom: "10px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            <bold style={{ color: "black" }}>What is Bravo Studio?            </bold>
          </VuiTypography>

          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              // fontWeight: "bold",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            Bravo Studio is a powerful no-code tool focused on turning Figma designs into fully functional mobile apps. With Bravo Studio, designers and developers can collaborate seamlessly to transform UI prototypes into production-ready apps. Like Adalo, it allows users to export source code, ensuring flexibility and control over their projects.

          </VuiTypography>

          <br />

          <VuiTypography
            style={{
              component: "h1",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.8vw",
              fontWeight: "bold",
              color: "#69696a",
              marginBottom: "10px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            <bold style={{ color: "black" }}>Key Features</bold>
          </VuiTypography>

          <VuiTypography
            style={{
              component: "h3",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              // fontWeight: "bold",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            <li>

              Converts Figma designs into mobile apps
            </li>
            <li>

              Support for advanced features like APIs and animations
            </li>
            <li>

              Export source code for iOS and Android apps
            </li>

          </VuiTypography>
          {/* what is flutterflow end */}

        </VuiBox>
        {/* section one end */}


        {/* section 2 heading start */}
        <VuiBox
          display="flex"
          justifyContent="space-between"
          flexDirection={isMobile ? "column" : "column"}
          // alignItems="start"
          width={isMobile ? "100%" : "90%"}
          marginTop="5%"
          padding="40px"
          style={{
            background: "transparent",
          }}
          borderRadius="25px"
        >
          <VuiTypography
            style={{
              component: "h1",

              textAlign: "center",
              fontSize: isMobile ? "6.5vw" : "3.2vw",
              fontWeight: "bold",
              color: "black",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            Key Comparison Factors

          </VuiTypography>



        </VuiBox>
        {/* section 2 heading end */}

        {/* section 2 start */}
        <VuiBox
          display="flex"
          justifyContent="space-between"
          flexDirection={isMobile ? "column" : "column"}
          // alignItems="start"
          width={isMobile ? "100%" : "90%"}
          marginTop="5%"
          padding="40px"
          style={{
            background: "white",
          }}
          borderRadius="25px"
        >
          <VuiTypography
            style={{
              component: "h1",

              textAlign: "center",
              fontSize: isMobile ? "6.5vw" : "3.2vw",
              fontWeight: "bold",
              color: "#fe3737",
              marginBottom: "50px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            User-Friendliness
          </VuiTypography>

          {/* heading start */}
          <VuiTypography
            style={{
              textAlign: "start",
              component: "h1",

              fontSize: isMobile ? "4.5vw" : "2vw",
              fontWeight: "bold",
              color: "#69696a",
              marginBottom: "20px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            <bold style={{ color: "black" }}>How Easy Are These Tools to Use?</bold>
          </VuiTypography>
          {/* heading end */}

          {/* bubble */}
          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              fontWeight: 'bold',
              color: "#fe3737",
              marginBottom: "10px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            Adalo



          </VuiTypography>


          <VuiTypography
            style={{
              component: "h3",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            Adalo’s interface is simple and intuitive, catering to users with no prior app development experience. Its drag-and-drop editor and pre-built components make it easy to design apps from scratch.
          </VuiTypography>
          {/* bubble end */}

          <br />

          {/* flutter  */}

          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              fontWeight: 'bold',
              color: "#fe3737",
              marginBottom: "10px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            Bravo Studio


          </VuiTypography>


          <VuiTypography
            style={{
              component: "h3",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            Bravo Studio offers a straightforward workflow but assumes some familiarity with design tools like Figma. Users must create their app designs in Figma first, which could be a hurdle for beginners.
          </VuiTypography>
          {/* flutter end */}

          <br />

          {/* winner  */}

          <VuiTypography
            style={{
              component: "h1",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              fontWeight: 'bold',
              color: "#fe3737",
              marginBottom: "10px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            Winner


          </VuiTypography>


          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            Adalo, for its beginner-friendly interface and fewer dependencies.
          </VuiTypography>
          {/* winner end */}


        </VuiBox>
        {/* section 2 end */}

        {/* section 3 start */}
        <VuiBox
          display="flex"
          justifyContent="space-between"
          flexDirection={isMobile ? "column" : "column"}
          // alignItems="start"
          width={isMobile ? "100%" : "90%"}
          marginTop="5%"
          padding="40px"
          style={{
            background: "white",
          }}
          borderRadius="25px"
        >
          <VuiTypography
            style={{
              component: "h1",

              textAlign: "center",
              fontSize: isMobile ? "6.5vw" : "3.2vw",
              fontWeight: "bold",
              color: "#fe3737",
              marginBottom: "50px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            Supported Platforms
          </VuiTypography>

          {/* heading start */}
          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "2vw",
              fontWeight: "bold",
              color: "#69696a",
              marginBottom: "20px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            <bold style={{ color: "black" }}>Where Can You Deploy Your Apps?</bold>
          </VuiTypography>
          {/* heading end */}

          {/* bubble */}
          <VuiTypography
            style={{
              component: "h1",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              fontWeight: 'bold',
              color: "#fe3737",
              marginBottom: "10px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            Adalo



          </VuiTypography>


          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            Adalo supports both mobile and web apps, making it a versatile choice for developers looking to target multiple platforms.


          </VuiTypography>
          {/* bubble end */}

          <br />

          {/* flutter  */}

          <VuiTypography
            style={{
              component: "h1",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              fontWeight: 'bold',
              color: "#fe3737",
              marginBottom: "10px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            Bravo Studio




          </VuiTypography>


          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            Bravo Studio focuses exclusively on mobile apps, supporting both iOS and Android but excluding web apps.
          </VuiTypography>
          {/* flutter end */}

          <br />

          {/* winner  */}

          <VuiTypography
            style={{
              component: "h1",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              fontWeight: 'bold',
              color: "#fe3737",
              marginBottom: "10px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            Winner


          </VuiTypography>


          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            Adalo, for its broader platform support.
          </VuiTypography>
          {/* winner end */}


        </VuiBox>
        {/* section 3 end */}
        {/* section 5 start */}
        <VuiBox
          display="flex"
          justifyContent="space-between"
          flexDirection={isMobile ? "column" : "column"}
          // alignItems="start"
          width={isMobile ? "100%" : "90%"}
          marginTop="5%"
          padding="40px"
          style={{
            background: "white",
          }}
          borderRadius="25px"
        >
          <VuiTypography
            style={{
              component: "h1",

              textAlign: "center",
              fontSize: isMobile ? "6.5vw" : "3.2vw",
              fontWeight: "bold",
              color: "#fe3737",
              marginBottom: "50px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            Source Code Ownership
          </VuiTypography>

          {/* heading start */}
          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "2vw",
              fontWeight: "bold",
              color: "#69696a",
              marginBottom: "20px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            <bold style={{ color: "black" }}>How Flexible Are These Platforms?</bold>
          </VuiTypography>
          {/* heading end */}

          {/* bubble */}
          <VuiTypography
            style={{
              component: "h1",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              fontWeight: 'bold',
              color: "#fe3737",
              marginBottom: "10px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            Adalo


          </VuiTypography>


          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            Adalo allows users to export their app’s source code, ensuring full ownership and enabling advanced customization for future development.
          </VuiTypography>
          {/* bubble end */}

          <br />

          {/* flutter  */}

          <VuiTypography
            style={{
              component: "h1",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              fontWeight: 'bold',
              color: "#fe3737",
              marginBottom: "10px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            Bravo Studio


          </VuiTypography>


          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            Bravo Studio also supports source code export, giving developers the freedom to further enhance their apps outside the platform.
          </VuiTypography>
          {/* flutter end */}

          <br />

          {/* winner  */}

          <VuiTypography
            style={{
              component: "h1",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              fontWeight: 'bold',
              color: "#fe3737",
              marginBottom: "10px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            Winner


          </VuiTypography>


          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            Tie, as both platforms provide source code ownership.

          </VuiTypography>
          {/* winner end */}


        </VuiBox>
        {/* section 5 end */}

        {/* section 4 start */}
        <VuiBox
          display="flex"
          justifyContent="space-between"
          flexDirection={isMobile ? "column" : "column"}
          // alignItems="start"
          width={isMobile ? "100%" : "90%"}
          marginTop="5%"
          padding="40px"
          style={{
            background: "white",
          }}
          borderRadius="25px"
        >
          <VuiTypography
            style={{
              component: "h1",

              textAlign: "center",
              fontSize: isMobile ? "6.5vw" : "3.2vw",
              fontWeight: "bold",
              color: "#fe3737",
              marginBottom: "50px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            Integrations
          </VuiTypography>

          {/* heading start */}
          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "2vw",
              fontWeight: "bold",
              color: "#69696a",
              marginBottom: "20px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            <bold style={{ color: "black" }}>What Can You Connect to Your App?</bold>
          </VuiTypography>
          {/* heading end */}

          {/* bubble */}
          <VuiTypography
            style={{
              component: "h1",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              fontWeight: 'bold',
              color: "#fe3737",
              marginBottom: "10px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            Adalo


          </VuiTypography>


          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            Adalo offers native integrations with tools like Zapier, Stripe, and Airtable, along with API support for additional connections.

          </VuiTypography>
          {/* bubble end */}

          <br />

          {/* flutter  */}

          <VuiTypography
            style={{
              component: "h1",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              fontWeight: 'bold',
              color: "#fe3737",
              marginBottom: "10px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            Bravo Studio



          </VuiTypography>


          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            Bravo Studio excels in API integration, enabling users to connect their apps with external services like REST APIs and Firebase for dynamic functionality.


          </VuiTypography>
          {/* flutter end */}

          <br />

          {/* winner  */}

          <VuiTypography
            style={{
              component: "h1",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              fontWeight: 'bold',
              color: "#fe3737",
              marginBottom: "10px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            Winner


          </VuiTypography>


          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            Bravo Studio, for its superior API integration capabilities.

          </VuiTypography>
          {/* winner end */}


        </VuiBox>
        {/* section 4 end */}


        {/* section 5 start */}
        <VuiBox
          display="flex"
          justifyContent="space-between"
          flexDirection={isMobile ? "column" : "column"}
          // alignItems="start"
          width={isMobile ? "100%" : "90%"}
          marginTop="5%"
          padding="40px"
          style={{
            background: "white",
          }}
          borderRadius="25px"
        >
          <VuiTypography
            style={{
              component: "h1",

              textAlign: "center",
              fontSize: isMobile ? "6.5vw" : "3.2vw",
              fontWeight: "bold",
              color: "#fe3737",
              marginBottom: "50px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            Learning Curve
          </VuiTypography>

          {/* heading start */}
          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "2vw",
              fontWeight: "bold",
              color: "#69696a",
              marginBottom: "20px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            <bold style={{ color: "black" }}>Which Tool is Faster to Master?</bold>
          </VuiTypography>
          {/* heading end */}

          {/* bubble */}
          <VuiTypography
            style={{
              component: "h1",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              fontWeight: 'bold',
              color: "#fe3737",
              marginBottom: "10px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            Adalo


          </VuiTypography>


          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            Adalo’s drag-and-drop system is straightforward, and users can start building apps without any prior experience. Tutorials and documentation are available to ease the process.
          </VuiTypography>
          {/* bubble end */}

          <br />

          {/* flutter  */}

          <VuiTypography
            style={{
              component: "h1",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              fontWeight: 'bold',
              color: "#fe3737",
              marginBottom: "10px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            Bravo Studio

          </VuiTypography>


          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            While Bravo Studio is beginner-friendly, it requires familiarity with Figma, which could extend the learning curve for users without design experience.

          </VuiTypography>
          {/* flutter end */}

          <br />

          {/* winner  */}

          <VuiTypography
            style={{
              component: "h1",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              fontWeight: 'bold',
              color: "#fe3737",
              marginBottom: "10px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            Winner


          </VuiTypography>


          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            Adalo, for its lower barrier to entry.
          </VuiTypography>
          {/* winner end */}


        </VuiBox>
        {/* section 5 end */}


        {/* section 6 heading start */}
        <VuiBox
          display="flex"
          justifyContent="space-between"
          flexDirection={isMobile ? "column" : "column"}
          // alignItems="start"
          width={isMobile ? "100%" : "90%"}
          marginTop="5%"
          padding="40px"
          style={{
            background: "transparent",
          }}
          borderRadius="25px"
        >
          <VuiTypography
            style={{
              component: "h1",

              textAlign: "center",
              fontSize: isMobile ? "6.5vw" : "3.2vw",
              fontWeight: "bold",
              color: "black",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            Pros and Cons

          </VuiTypography>



        </VuiBox>
        {/* section 6 heading end */}

        {/* section 6 start */}
        <VuiBox
          display="flex"
          justifyContent="space-between"
          flexDirection={isMobile ? "column" : "column"}
          // alignItems="start"
          width={isMobile ? "100%" : "90%"}
          marginTop="5%"
          padding="40px"
          style={{
            background: "white",
          }}
          borderRadius="25px"
        >


          {/* heading start */}
          <VuiTypography
            style={{
              component: "h1",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "2vw",
              fontWeight: "bold",
              color: "#008000",
              marginBottom: "20px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            Adalo Pros
          </VuiTypography>
          {/* heading end */}



          <VuiTypography
            style={{
              component: "h3",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            <li>

              Beginner-friendly drag-and-drop interface
            </li>
            <li>
            Supports both mobile and web apps
            </li>
            <li>
            Built-in monetization features
            </li>
            <li>
            Option to export source code
            </li>
          </VuiTypography>


          <br />



          {/* heading start */}
          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "2vw",
              fontWeight: "bold",
              color: "#fe3737",
              marginBottom: "20px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            Adalo Cons
          </VuiTypography>
          {/* heading end */}



          <VuiTypography
            style={{
              component: "h3",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            <li>
            Limited advanced design customization compared to Bravo Studio
            </li>
            <li>
            Fewer options for animations and advanced interactivity
            </li>

          </VuiTypography>

          {/* bubble end */}




        </VuiBox>
        {/* section 6 end */}


        {/* section 7 start */}
        <VuiBox
          display="flex"
          justifyContent="space-between"
          flexDirection={isMobile ? "column" : "column"}
          // alignItems="start"
          width={isMobile ? "100%" : "90%"}
          marginTop="5%"
          padding="40px"
          style={{
            background: "white",
          }}
          borderRadius="25px"
        >


          {/* heading start */}
          <VuiTypography
            style={{
              component: "h1",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "2vw",
              fontWeight: "bold",
              color: "#008000",
              marginBottom: "20px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            Bravo Studio Pros
          </VuiTypography>
          {/* heading end */}



          <VuiTypography
            style={{
              component: "h3",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            <li>
            	Ideal for turning Figma designs into apps

            </li>
            <li>
            Excellent API integration capabilities
            </li>
            <li>
            Advanced animations and transitions
            </li>
            <li>
            Source code export for iOS and Android
            </li>
          </VuiTypography>


          <br />



          {/* heading start */}
          <VuiTypography
            style={{
              component: "h1",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "2vw",
              fontWeight: "bold",
              color: "#fe3737",
              marginBottom: "20px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            Bravo Studio Cons
          </VuiTypography>
          {/* heading end */}



          <VuiTypography
            style={{
              component: "h3",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            <li>
            Requires familiarity with Figma for app design
            </li>
            <li>
            Limited to mobile apps (no web app support)
            </li>

          </VuiTypography>

          {/* bubble end */}




        </VuiBox>
        {/* section 7 end */}


        {/* section 8 start */}
        <VuiBox
          display="flex"
          justifyContent="space-between"
          flexDirection={isMobile ? "column" : "column"}
          // alignItems="start"
          width={isMobile ? "100%" : "90%"}
          marginTop="5%"
          padding="40px"
          style={{
            background: "white",
          }}
          borderRadius="25px"
        >
          <VuiTypography
            style={{
              component: "h1",

              textAlign: "center",
              fontSize: isMobile ? "6.5vw" : "3.2vw",
              fontWeight: "bold",
              color: "#fe3737",
              marginBottom: "50px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
Which Platform is Best for You?
</VuiTypography>

          {/* heading start */}
          <VuiTypography
            style={{
              component: "h2",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "2vw",
              fontWeight: "bold",
              color: "#69696a",
              marginBottom: "20px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            <bold style={{ color: "black" }}>Which Tool is Faster to Master?</bold>
          </VuiTypography>
          {/* heading end */}

          {/* bubble */}
          <VuiTypography
            style={{
              component: "h3",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              fontWeight: 'bold',
              color: "#fe3737",
              marginBottom: "10px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

The choice between Adalo and Bravo Studio depends on your specific needs:

          </VuiTypography>


          <VuiTypography
            style={{
              component: "h3",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            <li>
            Choose Adalo if you’re looking for a versatile platform that supports mobile and web apps, or if you’re a beginner seeking an easy-to-use app builder.
            </li>
            <li>
            Choose Bravo Studio if you’re a designer working with Figma and need a tool to transform your designs into polished, functional mobile apps.
            </li>

            <br />

            Both platforms are excellent for simplifying app development while giving you control over your code, so it ultimately comes down to your project’s requirements and your preferred workflow.          </VuiTypography>
          {/* bubble end */}



        </VuiBox>
        {/* section 8 end */}


        {/* section 9 start */}
        <VuiBox
          display="flex"
          justifyContent="space-between"
          flexDirection={isMobile ? "column" : "column"}
          // alignItems="start"
          width={isMobile ? "100%" : "90%"}
          marginTop="5%"
          padding="40px"
          style={{
            background: "white",
          }}
          borderRadius="25px"
        >
          <VuiTypography
            style={{
              component: "h2",

              textAlign: "center",
              fontSize: isMobile ? "6.5vw" : "3.2vw",
              fontWeight: "bold",
              color: "#fe3737",
              marginBottom: "50px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >
            Explore the Future of App Development with AI-Powered Tools
          </VuiTypography>

          {/* heading start */}
          <VuiTypography
            style={{
              component: "h3",

              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "1.6vw",
              // fontWeight: "bold",
              color: "#69696a",
              marginBottom: "20px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            <bold style={{
              color: 'black', fontWeight: "bold", fontSize: isMobile ? "4.5vw" : "2vw",
            }}>
              While platforms like Adalo and Bravo Studio simplify app development, they still require time and effort to master.
            </bold>
            <br />
            <br />

            That’s where AI-powered solutions like CodeGrow step in.
          </VuiTypography>
          {/* heading end */}

          {/* bubble */}
          <VuiTypography
            style={{
              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "2vw",
              fontWeight: 'bold',
              color: "black",
              marginBottom: "10px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >

            CodeGrow is a fully automated AI app developer that takes your app ideas and transforms them into functional iOS applications with minimal effort.
          </VuiTypography>


          <VuiTypography
            style={{
              textAlign: "start",
              fontSize: isMobile ? "4.5vw" : "2vw",
              color: "#69696a",
              marginBottom: "0px",
              lineHeight: isMobile ? "1.1" : "1.2",
            }}
          >


            If you’re looking to move beyond no-code tools and tap into the next level of automation, CodeGrow is here to help. Build iOS apps faster, smarter, and easier than ever before.
            Ready to take your app ideas to the next level?
            <br />
            <br />

            <center>
              <br />

              <VuiButton
                aligncontent="center"
                variant="contained"
                color="primary"
                size="medium"
                onClick={() => navigateToSignIn()}
                style={{
                  backgroundColor: '#1079FB',

                  color: " white",
                  fontSize: isMobile ? "3.5vw" : "1.5vw",
                  padding: "10px 20px",
                  borderRadius: '55px',
                  marginBottom: isMobile ? "10%" : "0px"

                }}
              >
                Start For Free Today
              </VuiButton>
              <br />
            </center>

          </VuiTypography>
          {/* bubble end */}



        </VuiBox>
        {/* section 9 end */}
      </VuiBox>




      {/* fourth section start */}
      <VuiBox
        style={{
          width: '100vw',
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
          backgroundColor: "#F7F8FB",

          minHeight: "100vh",
          marginTop: '0px',

          padding: "0", // Remove padding to avoid affecting width
        }}
      >





        {/* third white bubble*/}
        <VuiBox

          width={isMobile ? "100%" : "90%"}
          // maxWidth="800px"
          marginTop="5%"
          height={isMobile ? "150vw" : " 75vw"}
          padding="40px"
          style={{
            backgroundImage: `url(${minicolourbg})`, // Background image for the landing page

            backgroundSize: "cover", // Ensures the image covers the entire area
            backgroundPosition: "center", // Centers the image
            backgroundRepeat: "no-repeat", // Prevents the image from repeating
          }}
          borderRadius="25px"
        >
          <VuiBox
            display="flex"
            flexDirection="column"

            justifyContent={"space-between"}
            alignItems="center"
            marginLeft={isMobile ? "0px" : '15%'}
            width={isMobile ? "100%" : "70%"}
            // maxWidth="800px"

            height={" 100%"}
            padding="0px"

            borderRadius="25px"
          >


            <VuiTypography
              style={{
                textAlign: "center",
                fontSize: isMobile ? "6.5vw" : "5.8vw",
                fontWeight: "bold",
                color: "white",
                marginTop: "15px",
                lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here
              }}
            >
              <bold style={{
                color: "#FE3737",
              }}>               Start For Free Today
              </bold>
            </VuiTypography>


            <VuiTypography
              style={{
                marginTop: isMobile ? "10%" : "15px",

                textAlign: "center",
                fontSize: isMobile ? "5.5vw" : "1.6vw",
                fontWeight: "bold",
                color: "white",
                marginBottom: isMobile ? "6%" : "0px",
                lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here
              }}
            >

              Launch your first app project with expert guidance and AI tools.            </VuiTypography>
            <br />

            <VuiButton
              aligncontent="center"
              variant="contained"
              color="primary"
              size="medium"
              onClick={() => navigateToSignIn()}
              style={{
                backgroundColor: '#1079FB',

                color: " white",
                fontSize: isMobile ? "3.5vw" : "1.5vw",
                padding: "10px 20px",
                borderRadius: '55px',
                marginBottom: isMobile ? "10%" : "0px"

              }}
            >
              Start For Free Today
            </VuiButton>
            <br />
            <img
              src={macbook} // Replace with your GIF URL
              alt="Centered Image"
              style={{
                borderRadius: "15px",
                width: isMobile ? "90vw" : "70vw",
                objectFit: "contain", // Ensures the image scales nicely within the specified width
                marginBottom: "-0%", // Centers the image horizontally
                boxShadow: "0 0 20px 5px rgba(255, 255, 255, 0.7)", // Adds a white glow effect
              }}
            />


          </VuiBox>


        </VuiBox>
        {/* third white bubble end */}


        {/* FAQ */}
        <VuiBox
          style={{
            marginTop: "100px",
            textAlign: "center",
            padding: "40px 20px",


            background: "linear-gradient(to top, #030515 0%, #7f8c8d 70%, #f7f8fb 100%)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
          }}
        >
          <VuiTypography
            style={{
              fontSize: "48px",
              color: "#FC3837",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <b style={{ fontSize: isMobile ? "5.8vw" : "4.2vw", color: "black" }}>
              Frequently Asked Questions
            </b>
          </VuiTypography>

          {/* FAQ list */}
          <VuiBox
            style={{
              width: isMobile ? "90%" : "80%",
              maxWidth: "1200px",
              marginTop: "30px",
            }}
          >
            {faqData.map((faq, index) => (
              <VuiBox
                key={index}
                style={{
                  marginBottom: "20px",
                  padding: "20px",
                  background: "#1a1a2e",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                onClick={() => toggleQuestion(index)}
              >
                {/* Question */}
                <VuiTypography
                  style={{
                    color: "#FC3837",
                    fontSize: isMobile ? "4vw" : "1.5vw",
                  }}
                >
                  {faq.question}
                </VuiTypography>

                {/* Answer */}
                {openQuestion === index && (
                  <VuiTypography
                    style={{
                      marginTop: "10px",
                      fontSize: isMobile ? "4vw" : "1.2vw",
                      color: "white",
                      transition: "max-height 0.3s ease-in-out",
                      overflow: "hidden",
                    }}
                  >
                    {faq.answer}
                  </VuiTypography>
                )}
              </VuiBox>
            ))}
          </VuiBox>
        </VuiBox>
        {/* triple section end */}


      </VuiBox>
      {/* fourth section end */}



      <br />



    </CoverLayout>
    </>
  );
}

export default SignIn;
