export const handleGenerateScreenshot3 = async (screenshot, iPhone, bg, heading, subheading) => {
  if (!screenshot) {
    alert("Please upload a screenshot first.");
    return;
  }

  try {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const bgImage = new Image();
    const frameImage = new Image();
    const userImage = new Image();

    bgImage.src = bg;
    frameImage.src = iPhone;
    userImage.src = URL.createObjectURL(screenshot);

    const loadImage = (image) => {
      return new Promise((resolve, reject) => {
        image.onload = resolve;
        image.onerror = reject;
      });
    };

    await Promise.all([loadImage(bgImage), loadImage(frameImage), loadImage(userImage)]);

    canvas.width = 1290;
    canvas.height = 2796;

    ctx.drawImage(bgImage, 0, 0, canvas.width, canvas.height);

    // Add heading above the image
    ctx.fillStyle = "black";
    ctx.font = "bold 100px Arial"; // Larger font size for heading
    ctx.textAlign = "center";
    const textPadding = 320; // Space between the text and the top of the canvas
    ctx.fillText(heading.toUpperCase(), canvas.width / 2, textPadding);

    // Add subheading below the heading
    ctx.font = "bold 85px Arial"; // Smaller font size for subheading
    ctx.fillText(subheading.toUpperCase(), canvas.width / 2, textPadding + 220);

    const combinedCanvas = document.createElement("canvas");
    const combinedCtx = combinedCanvas.getContext("2d");
    const combinedWidth = canvas.width;
    const combinedHeight = 2796;
    combinedCanvas.width = combinedWidth;
    combinedCanvas.height = combinedHeight;

    const userImageWidth = canvas.width * 0.92;
    const userImageHeight = 2680;
    const userX = (combinedWidth - userImageWidth) / 2;
    const userY = (combinedHeight - userImageHeight) / 2;
    const radius = 65;

    combinedCtx.save();
    combinedCtx.beginPath();
    combinedCtx.moveTo(userX + radius, userY);
    combinedCtx.lineTo(userX + userImageWidth - radius, userY);
    combinedCtx.arcTo(userX + userImageWidth, userY, userX + userImageWidth, userY + userImageHeight, radius);
    combinedCtx.lineTo(userX + userImageWidth, userY + userImageHeight - radius);
    combinedCtx.arcTo(userX + userImageWidth, userY + userImageHeight, userX + userImageWidth - radius, userY + userImageHeight, radius);
    combinedCtx.lineTo(userX + radius, userY + userImageHeight);
    combinedCtx.arcTo(userX, userY + userImageHeight, userX, userY + userImageHeight - radius, radius);
    combinedCtx.lineTo(userX, userY + radius);
    combinedCtx.arcTo(userX, userY, userX + radius, userY, radius);
    combinedCtx.clip();
    combinedCtx.drawImage(userImage, userX, userY, userImageWidth, userImageHeight);
    combinedCtx.restore();

    combinedCtx.drawImage(frameImage, 0, 0, combinedWidth, combinedHeight);

    const finalX = (canvas.width - combinedWidth) / 4;
    const finalY = 700; // Adjust to position the image below the text
    ctx.drawImage(combinedCanvas, finalX, finalY, combinedWidth, combinedHeight);

    canvas.toBlob(
      (blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "app-store-screenshot.png";
        link.click();
        URL.revokeObjectURL(link.href);
      },
      "image/png"
    );
  } catch (error) {
    console.error("Error generating App Store screenshot:", error);
  }
};
