import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import VuiBox from "components/VuiBox";
import { useVisionUIController, setLayout } from "context";
import { useAuth } from "context/AuthContext";
import { Snackbar, Alert, Modal } from "@mui/material";
import axios from 'axios';
import PricingTable from '../../../components/PricingTable'; // Adjust the path as necessary
import { useMediaQuery } from '@mui/material';
import VuiButton from "components/VuiButton"; // Import Button if needed
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import dialog from "assets/theme/components/dialog";

function FreeLayout({ children }) {
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [isProUser, setIsProUser] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openBreakdownModal, setOpenBreakdownModal] = useState(false); // New state for package breakdown modal
  const isMobile = useMediaQuery('(max-width:600px)');

  // const checkProAccount = async (userEmail) => {
  //   try {
  //     const response = await axios.post('https://checksubscription-wkmofyogxa-uc.a.run.app', { email: userEmail });
  //     console.log("Full Response:", response);

  //     // Log just the data
  //     console.log("Response Data:", response.data);
  
    
  //     setIsProUser(response.data.active);
    
  //     if (!response.data.active) {
  //       setOpenModal(true);
  //     } else {
  //       console.log("User is subscribed to these products:", response.data.products);
    
  //       // Check which subscription the user has by product ID
  //       if (response.data.products.includes("prod_R4OZRuMOJn2RXX")) {
  //         console.log("User has the first subscription (prod_R4OZRuMOJn2RXX)");
  //       } 
  //       if (response.data.products.includes("prod_R4OaST7dmwUbVR")) {
  //         console.log("User has the second subscription (prod_R4OaST7dmwUbVR)");
  //       }
  //     }
  //   }catch (error) {
  //     console.error('Error checking subscription:', error);
  //     if (error.response && error.response.status === 404) {
  //       setOpenModal(true);
  //     }
  //     setIsProUser(false);
  //   }
  // };
  const checkProAccount = async (userEmail) => {
    try {
      const response = await axios.post('https://checksubscription-wkmofyogxa-uc.a.run.app', { email: userEmail });
      console.log("Full Response:", response);
  
      // Log just the data
      console.log("Response Data:", response.data);
  
      const { activeOrTrial, trialEndingSoon, products } = response.data;
  
      // Treat trial members as Pro users or if trial is ending soon
      const isPro = activeOrTrial || trialEndingSoon;
      setIsProUser(isPro);
  
      if (!isPro) {
        // Show modal if neither active nor on trial, and trial isn't ending soon
        setOpenModal(true);
      } else {
        console.log("User is subscribed to these products:", products);
  
        // Check if trial is ending soon
        if (trialEndingSoon) {
          console.log(`User's trial is ending soon. Check your email for renewal details.`);
        }
  
        // Check which subscription the user has by product ID
        if (products.includes("prod_R4OZRuMOJn2RXX")) {
          console.log("User has the first subscription (prod_R4OZRuMOJn2RXX)");
        }
        if (products.includes("prod_R4OaST7dmwUbVR")) {
          console.log("User has the second subscription (prod_R4OaST7dmwUbVR)");
        }
      }
    } catch (error) {
      console.error('Error checking subscription:', error);
  
      if (error.response && error.response.status === 404) {
        // User not found in Stripe, show modal
        setOpenModal(true);
      }
      setIsProUser(false);
    }
  };
  
  // useEffect(() => {
  //   if (currentUser) {
  //     checkProAccount(currentUser.email);
  //   }
  // }, [currentUser]);

  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    setLayout(dispatch, "dashboard");
    if (!currentUser) {
      setOpenSnackbar(true);
      navigate("/authentication/sign-in");
    }
  }, [currentUser, dispatch, navigate]);

  const handleLogout = () => {
    setOpenModal(false);
    navigate("/authentication/logout");
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    navigate("/dashboard");
  };

  const handleViewPackageBreakdown = () => {
    setOpenBreakdownModal(true); // Open breakdown modal when the button is clicked
  };

  const handleCloseBreakdownModal = () => {
    setOpenBreakdownModal(false);
  };

  return (
    <>
      <VuiBox
        sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
          p: 3,
          position: "relative",
          minHeight: "calc(100vh - 0px)",
          [breakpoints.up("xl")]: {
            marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
            transition: transitions.create(["margin-left", "margin-right"], {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.standard,
            }),
          },
        })}
      >
        {children}
      </VuiBox>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity="warning">
          You need to log in to access this content.
        </Alert>
      </Snackbar>

      {/* Main Modal */}
      {openModal && (
        // <div style={{
        //   position: 'fixed',
        //   top: isMobile ? '60%' : '50%',
        //   left: '50%',
        //   transform: 'translate(-50%, -50%)',
        //   background: '#fff',
        //   padding: '20px',
        //   borderRadius: '8px',
        //   zIndex: 1000,
        //   boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        //   height: isMobile ? '80%' : '',
        //   width: isMobile ? '100%' : '',
        // }}>
        <Dialog
          open={openModal}
          onClose={handleCloseBreakdownModal}
          maxWidth={ isMobile? "100vw" : '50vw' }
          fullWidth
      
          PaperProps={{
            style: {  width: isMobile? "100vw" :'40vw', borderRadius:'15px',  padding: isMobile? "15px" : "30px",backgroundColor: 'white', color: 'black', height: isMobile? "75vh" :'80vh' }, // Customize modal background color
          }}
        >

          <button
            onClick={handleCloseModal}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'transparent',
              border: 'none',
              fontSize: '16px',
              cursor: 'pointer'
            }}
          >
            &#x2715; {/* Unicode for the 'X' symbol */}
          </button>
          <h2 style={{marginTop:'5px'}}>You don't have a Pro account</h2>
          <p>Upgrade to a Pro account to access this content.</p>
          <br/>
          <VuiButton style={{ color: 'blue' }} onClick={handleViewPackageBreakdown}>
            View Package Breakdown
          </VuiButton>
          <br/>
          <PricingTable /> {/* Render PricingTable inside the modal */}
          <VuiButton style={{ color: 'blue', marginTop: isMobile? '0px' : '50px'}} onClick={handleLogout}>
            Logout
          </VuiButton>
          </Dialog>

      )}

          {/* Package Breakdown Modal */}
          {openBreakdownModal && (
            <Dialog
              open={openModal}
              onClose={handleCloseBreakdownModal}
              maxWidth="80vw" // Adjust the width as needed
              fullWidth
              PaperProps={{
                style: { backgroundColor: '#333', color: 'white', height: '80vh' }, // Customize modal background color
              }}
            >

              <div style={{
                background: '#fff',
                padding: '20px',
                borderRadius: '8px',
                overflowY: 'auto',
                position: 'relative',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                marginTop: isMobile ? '0px' : '0px',
              }}>
                <button
                  onClick={handleCloseBreakdownModal}
                  style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    background: 'transparent',
                    border: 'none',
                    fontSize: '16px',
                    cursor: 'pointer'
                  }}
                >
                  &#x2715; {/* Unicode for the 'X' symbol */}
                </button>
                <h2 style={{ color: 'black' }}>Package Breakdown</h2>
                <br />

                {/* YouTube video */}
                <div style={{ marginBottom: '20px' }}>
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/JaGNs45A_c8?si=mWIUqcRjCL_p1ReG" // Replace with your actual video link
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>

                {/* Table with package comparison */}
                <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-evenly', marginBottom: '20px' }}>
                  <div>
                    <h2 style={{ color: 'black' }}>Monthly Package</h2>
                    <ul style={{ listStyleType: 'none', paddingLeft: 0, backgroundColor: '#FC3837', color: 'white', borderRadius: '10px', padding: '10px' }}>
                      {[
                         "AI Idea Finder",
                         "AI App Designer",
                         "AI Coder",
                         "AI Code Expander",
                         "Canva Design Templates",
                         "Flippa Sale Templates",
                         "AI Chrome Extensions",
                         "App Store Design Lab",
                      ].map((feature, index) => (
                        <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                          <span style={{ color: 'white', marginRight: '8px' }}>✔</span> {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <br />
                  <div>
                    <h2 style={{ color: 'black' }}>Yearly Package</h2>
                    <ul style={{ listStyleType: 'none', paddingLeft: 0, backgroundColor: '#FC3837', color: 'white', borderRadius: '10px', padding: '10px' }}>
                      {[
                        "Full Market Ready IOS Apps",
                         "AI Idea Finder",
                         "AI App Designer",
                         "AI Coder",
                         "AI Code Expander",
                         "Canva Design Templates",
                         "Flippa Sale Templates",
                         "AI Chrome Extensions",
                         "App Store Design Lab",
                      ].map((feature, index) => (
                        <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                          <span style={{ color: 'white', marginRight: '8px' }}>✔</span> {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                {/* List of features with checkmarks */}

              </div>
            </Dialog>
          )}
        </>
      );
}

      FreeLayout.propTypes = {
        children: PropTypes.node.isRequired,
};

      export default FreeLayout;
