import React, { useState } from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import FreeLayout from "examples/LayoutContainers/FreeLayout";
import imageResizeAndZip from "./data/imageResize";
import { useMediaQuery } from '@mui/material';

import bg from "assets/images/app-store-screenshot-template/as-ss-bg.png";
import bg2 from "assets/images/app-store-screenshot-template/bg2.png";
import bg3 from "assets/images/app-store-screenshot-template/bg3.png";
import iPhone from "assets/images/app-store-screenshot-template/iphonebg.png";
import ipadbg from "assets/images/app-store-screenshot-template/ipadbg.png";
import ipadbg2 from "assets/images/app-store-screenshot-template/ipadbg2.png";
import ipadbg3 from "assets/images/app-store-screenshot-template/ipadbg3.png";
import ipad from "assets/images/app-store-screenshot-template/ipad.png";

import { handleGenerateScreenshot1 } from './handleGenerateScreenshot1';
import { handleGenerateScreenshot2 } from './handleGenerateScreenshot2';
import { handleGenerateScreenshot3 } from './handleGenerateScreenshot3';
import { handleGenerateScreenshot4 } from './handleGenerateScreenshot4';
import { handleGenerateScreenshot5 } from './handleGenerateScreenshot5';
import { handleGenerateScreenshot6 } from './handleGenerateScreenshot6';
import { handleGenerateScreenshot7 } from './handleGenerateScreenshot7';
import { handleGenerateScreenshot8 } from './handleGenerateScreenshot8';

function Iconsize() {
  const [file, setFile] = useState(null);
  const isMobile = useMediaQuery('(max-width:600px)');

  const [screenshot, setScreenshot] = useState(null);
  const [screenshot2, setScreenshot2] = useState(null);
  const [screenshot3, setScreenshot3] = useState(null);
  const [screenshot4, setScreenshot4] = useState(null);
  const [screenshot5, setScreenshot5] = useState(null);
  const [screenshot6, setScreenshot6] = useState(null);
  const [screenshot7, setScreenshot7] = useState(null);
  const [screenshot8, setScreenshot8] = useState(null);
  const [selectedBg, setSelectedBg] = useState(bg); // Default background
  const [selectedIpadBg, setSelectedIpadBg] = useState(ipadbg); // Default background

  const [heading, setHeading] = useState("");
  const [subheading, setSubheading] = useState("");

  const [heading2, setHeading2] = useState("");
  const [subheading2, setSubheading2] = useState("");


  const [heading3, setHeading3] = useState("");
  const [subheading3, setSubheading3] = useState("");

  const [heading4, setHeading4] = useState("");
  const [subheading4, setSubheading4] = useState("");

  const [heading5, setHeading5] = useState("");
  const [subheading5, setSubheading5] = useState("");

  const [heading6, setHeading6] = useState("");
  const [subheading6, setSubheading6] = useState("");


  const [heading7, setHeading7] = useState("");
  const [subheading7, setSubheading7] = useState("");

  const [heading8, setHeading8] = useState("");
  const [subheading8, setSubheading8] = useState("");

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleScreenshotChange = (event) => {
    setScreenshot(event.target.files[0]);
  };

  const handleScreenshotChange2 = (event) => {
    setScreenshot2(event.target.files[0]);
  };

  const handleScreenshotChange3 = (event) => {
    setScreenshot3(event.target.files[0]);
  };

  const handleScreenshotChange4 = (event) => {
    setScreenshot4(event.target.files[0]);
  };

  const handleScreenshotChange5 = (event) => {
    setScreenshot5(event.target.files[0]);
  };

  const handleScreenshotChange6 = (event) => {
    setScreenshot6(event.target.files[0]);
  };

  const handleScreenshotChange7 = (event) => {
    setScreenshot7(event.target.files[0]);
  };

  const handleScreenshotChange8 = (event) => {
    setScreenshot8(event.target.files[0]);
  };


  const handleGenerateIcons = async () => {
    if (file) {
      console.log("Generating icon sizes...");
      try {
        const zipFile = await imageResizeAndZip(file);
        console.log("Icon sizes generated successfully:", zipFile);
        const link = document.createElement("a");
        link.href = URL.createObjectURL(zipFile);
        link.download = "app-icons.zip";
        link.click();
        URL.revokeObjectURL(link.href); // Cleanup URL
      } catch (error) {
        console.error("Error generating icon sizes:", error);
      }
    } else {
      alert("Please upload an image first.");
    }
  };


  const handleBackgroundSelect = (bg) => {
    setSelectedBg(bg);
  };
  const handleIpadBackgroundSelect = (bg) => {
    setSelectedIpadBg(bg);
  };

  const handleGenerateScreenshot = async () => {
    handleGenerateScreenshot1(screenshot, iPhone, selectedBg, heading, subheading);
    handleGenerateScreenshot2(screenshot2, iPhone, selectedBg, heading2, subheading2);
    handleGenerateScreenshot3(screenshot3, iPhone, selectedBg, heading3, subheading3);
  }

  const handleGenerateIpadScreenshot = async () => {
    handleGenerateScreenshot5(screenshot5, ipad, selectedIpadBg, heading5, subheading5);
    handleGenerateScreenshot6(screenshot6, ipad, selectedIpadBg, heading6, subheading6);
    handleGenerateScreenshot7(screenshot7, ipad, selectedIpadBg, heading7, subheading7);
  }

  return (
    <FreeLayout>
      <DashboardNavbar />
      <VuiBox py={5}>

        <Card>
          <VuiBox display="flex" justifyContent="center" alignItems="center">
            <VuiTypography variant="xx-lg" color="white" style={{ fontSize: isMobile ? "5.5vw" : '2vw' }}>
              App Store Icon Generator            </VuiTypography>
          </VuiBox>
          <VuiBox py={2} display="flex" flexDirection="column" alignItems="center">
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{
                width: "250px",
                height: "50px",
                borderRadius: "8px",
                padding: "10px",
                backgroundColor: "#2D2E5F",
                color: "white",
                cursor: "pointer",
                textAlign: "center",
                border: "2px solid #ffffff",
                marginTop: "15px",
              }}
            />
            <Button
              onClick={handleGenerateIcons}
              variant="outlined"
              color="white"
              sx={{
                border: "none",
                width: "250px",
                height: "50px",
                mt: 5,
                color: "white",
                borderRadius: "8px",
                fontSize: "18px",
                backgroundColor: "#1976d2",
                "&:hover": {
                  backgroundColor: "#115293",
                },
              }}
            >
              Generate Icon Sizes
            </Button>
          </VuiBox>
        </Card>

        <br />

        <Card>
          <VuiBox display="flex" justifyContent="center" alignItems="center">
            <VuiTypography variant="xx-lg" color="white" style={{ fontSize: isMobile ? "5.5vw" : '2vw' }}>
              iPhone Screenshot Generator
            </VuiTypography>
          </VuiBox>
          <VuiBox py={3} display="flex" flexDirection="column" alignItems="center">
            <VuiTypography variant="h5" color="white" mb={4}>
              Choose a Background
            </VuiTypography>
            <VuiBox display="flex" gap={2}>
              {/* Background 1 */}
              <Button
                onClick={() => handleBackgroundSelect(bg)}
                sx={{
                  width: 60,
                  height: 60,
                  borderRadius: "50%",
                  backgroundColor: "#b2c9cf",
                  border: selectedBg === bg ? "3px solid white" : "none",
                  "&:hover": {
                    opacity: 0.8,
                  },
                }}
              ></Button>
              {/* Background 2 */}
              <Button
                onClick={() => handleBackgroundSelect(bg2)}
                sx={{
                  width: 60,
                  height: 60,
                  borderRadius: "50%",
                  backgroundColor: "#dddddd",
                  border: selectedBg === bg2 ? "3px solid white" : "none",
                  "&:hover": {
                    opacity: 0.8,
                  },
                }}
              ></Button>
              {/* Background 3 */}
              <Button
                onClick={() => handleBackgroundSelect(bg3)}
                sx={{
                  width: 60,
                  height: 60,
                  borderRadius: "50%",
                  backgroundColor: "#e2cac1",
                  border: selectedBg === bg3 ? "3px solid white" : "none",
                  "&:hover": {
                    opacity: 0.8,
                  },
                }}
              ></Button>


            </VuiBox>

          </VuiBox>
          <VuiBox style={{ display: 'flex', flexDirection: isMobile ? "column" : 'row', justifyContent: 'space-evenly' }}>

            {/* image 1 */}
            <VuiBox py={2} display="flex" flexDirection="column" alignItems="center">
              <VuiTypography variant="xx-lg" color="white">
                Image Set 1
              </VuiTypography>
              <input
                type="file"
                accept="image/*"
                onChange={handleScreenshotChange}
                style={{
                  width: "100%",
                  height: "50px",
                  borderRadius: "8px",
                  padding: "10px",
                  backgroundColor: "#2D2E5F",
                  color: "white",
                  cursor: "pointer",
                  textAlign: "center",
                  border: "2px solid #ffffff",
                  marginTop: "15px",
                }}
              />
              <input
                type="text"
                placeholder="Enter heading"
                value={heading}
                onChange={(e) => setHeading(e.target.value)}
                style={{
                  width: "100%",
                  marginTop: "15px",
                  padding: "10px",
                  borderRadius: "8px",
                }}
              />
              <input
                type="text"
                placeholder="Enter subheading"
                value={subheading}
                onChange={(e) => setSubheading(e.target.value)}
                style={{
                  width: "100%",
                  marginTop: "15px",
                  padding: "10px",
                  borderRadius: "8px",
                }}
              />

            </VuiBox>
            {/* image 1 end */}


            {/* image 2 */}
            <VuiBox py={2} display="flex" flexDirection="column" alignItems="center">
              <VuiTypography variant="xx-lg" color="white">
                Image Set 2
              </VuiTypography>
              <input
                type="file"
                accept="image/*"
                onChange={handleScreenshotChange2}
                style={{
                  width: "100%",
                  height: "50px",
                  borderRadius: "8px",
                  padding: "10px",
                  backgroundColor: "#2D2E5F",
                  color: "white",
                  cursor: "pointer",
                  textAlign: "center",
                  border: "2px solid #ffffff",
                  marginTop: "15px",
                }}
              />
              <input
                type="text"
                placeholder="Enter heading"
                value={heading2}
                onChange={(e) => setHeading2(e.target.value)}
                style={{
                  width: "100%",
                  marginTop: "15px",
                  padding: "10px",
                  borderRadius: "8px",
                }}
              />
              <input
                type="text"
                placeholder="Enter subheading"
                value={subheading2}
                onChange={(e) => setSubheading2(e.target.value)}
                style={{
                  width: "100%",
                  marginTop: "15px",
                  padding: "10px",
                  borderRadius: "8px",
                }}
              />

            </VuiBox>
            {/* image 2 end */}

            {/* image 3 */}
            <VuiBox py={2} display="flex" flexDirection="column" alignItems="center">
              <VuiTypography variant="xx-lg" color="white">
                Image Set 3
              </VuiTypography>
              <input
                type="file"
                accept="image/*"
                onChange={handleScreenshotChange3}
                style={{
                  width: "100%",
                  height: "50px",
                  borderRadius: "8px",
                  padding: "10px",
                  backgroundColor: "#2D2E5F",
                  color: "white",
                  cursor: "pointer",
                  textAlign: "center",
                  border: "2px solid #ffffff",
                  marginTop: "15px",
                }}
              />
              <input
                type="text"
                placeholder="Enter heading"
                value={heading3}
                onChange={(e) => setHeading3(e.target.value)}
                style={{
                  width: "100%",
                  marginTop: "15px",
                  padding: "10px",
                  borderRadius: "8px",
                }}
              />
              <input
                type="text"
                placeholder="Enter subheading"
                value={subheading3}
                onChange={(e) => setSubheading3(e.target.value)}
                style={{
                  width: "100%",
                  marginTop: "15px",
                  padding: "10px",
                  borderRadius: "8px",
                }}
              />

            </VuiBox>
            {/* image 3 end */}


          </VuiBox>




          {/* </VuiBox> */}
          <center>
            <Button
              onClick={handleGenerateScreenshot}
              variant="outlined"
              color="white"
              sx={{
                border: "none",
                width: "75%",
                height: "50px",
                mt: 5,
                color: "white",
                borderRadius: "8px",
                fontSize: "18px",
                backgroundColor: "#1976d2",
                "&:hover": {
                  backgroundColor: "#115293",
                },
              }}
            >
              Generate Screenshots
            </Button>
          </center>
        </Card>




        <br />

        <Card>
          <VuiBox display="flex" justifyContent="center" alignItems="center">
            <VuiTypography variant="xx-lg" color="white" style={{ fontSize: isMobile ? "5.5vw" : '2vw' }}>

              iPad Screenshot Generator
            </VuiTypography>
          </VuiBox>
          <VuiBox py={3} display="flex" flexDirection="column" alignItems="center">
            <VuiTypography variant="h5" color="white" mb={4}>
              Choose a Background
            </VuiTypography>
            <VuiBox display="flex" gap={2}>
              {/* Background 1 */}
              <Button
                onClick={() => handleIpadBackgroundSelect(ipadbg)}
                sx={{
                  width: 60,
                  height: 60,
                  borderRadius: "50%",
                  backgroundColor: "#b2c9cf",
                  border: selectedIpadBg === ipadbg ? "3px solid white" : "none",
                  "&:hover": {
                    opacity: 0.8,
                  },
                }}
              ></Button>
              {/* Background 2 */}
              <Button
                onClick={() => handleIpadBackgroundSelect(ipadbg2)}
                sx={{
                  width: 60,
                  height: 60,
                  borderRadius: "50%",
                  backgroundColor: "#dddddd",
                  border: selectedIpadBg === ipadbg2 ? "3px solid white" : "none",
                  "&:hover": {
                    opacity: 0.8,
                  },
                }}
              ></Button>
              {/* Background 3 */}
              <Button
                onClick={() => handleIpadBackgroundSelect(ipadbg3)}
                sx={{
                  width: 60,
                  height: 60,
                  borderRadius: "50%",
                  backgroundColor: "#e2cac1",
                  border: selectedIpadBg === ipadbg3 ? "3px solid white" : "none",
                  "&:hover": {
                    opacity: 0.8,
                  },
                }}
              ></Button>


            </VuiBox>

          </VuiBox>
          <VuiBox style={{ display: 'flex', flexDirection: isMobile ? "column" : 'row', justifyContent: 'space-evenly' }}>


            {/* image 1 */}
            <VuiBox py={2} display="flex" flexDirection="column" alignItems="center">
              <VuiTypography variant="xx-lg" color="white">
                Image Set 1
              </VuiTypography>
              <input
                type="file"
                accept="image/*"
                onChange={handleScreenshotChange5}
                style={{
                  width: "100%",
                  height: "50px",
                  borderRadius: "8px",
                  padding: "10px",
                  backgroundColor: "#2D2E5F",
                  color: "white",
                  cursor: "pointer",
                  textAlign: "center",
                  border: "2px solid #ffffff",
                  marginTop: "15px",
                }}
              />
              <input
                type="text"
                placeholder="Enter heading"
                value={heading5}
                onChange={(e) => setHeading5(e.target.value)}
                style={{
                  width: "100%",
                  marginTop: "15px",
                  padding: "10px",
                  borderRadius: "8px",
                }}
              />
              <input
                type="text"
                placeholder="Enter subheading"
                value={subheading5}
                onChange={(e) => setSubheading5(e.target.value)}
                style={{
                  width: "100%",
                  marginTop: "15px",
                  padding: "10px",
                  borderRadius: "8px",
                }}
              />

            </VuiBox>
            {/* image 1 end */}


            {/* image 2 */}
            <VuiBox py={2} display="flex" flexDirection="column" alignItems="center">
              <VuiTypography variant="xx-lg" color="white">
                Image Set 2
              </VuiTypography>
              <input
                type="file"
                accept="image/*"
                onChange={handleScreenshotChange6}
                style={{
                  width: "100%",
                  height: "50px",
                  borderRadius: "8px",
                  padding: "10px",
                  backgroundColor: "#2D2E5F",
                  color: "white",
                  cursor: "pointer",
                  textAlign: "center",
                  border: "2px solid #ffffff",
                  marginTop: "15px",
                }}
              />
              <input
                type="text"
                placeholder="Enter heading"
                value={heading6}
                onChange={(e) => setHeading6(e.target.value)}
                style={{
                  width: "100%",
                  marginTop: "15px",
                  padding: "10px",
                  borderRadius: "8px",
                }}
              />
              <input
                type="text"
                placeholder="Enter subheading"
                value={subheading6}
                onChange={(e) => setSubheading6(e.target.value)}
                style={{
                  width: "100%",
                  marginTop: "15px",
                  padding: "10px",
                  borderRadius: "8px",
                }}
              />

            </VuiBox>
            {/* image 2 end */}

            {/* image 3 */}
            <VuiBox py={2} display="flex" flexDirection="column" alignItems="center">
              <VuiTypography variant="xx-lg" color="white">
                Image Set 3
              </VuiTypography>
              <input
                type="file"
                accept="image/*"
                onChange={handleScreenshotChange7}
                style={{
                  width: "100%",
                  height: "50px",
                  borderRadius: "8px",
                  padding: "10px",
                  backgroundColor: "#2D2E5F",
                  color: "white",
                  cursor: "pointer",
                  textAlign: "center",
                  border: "2px solid #ffffff",
                  marginTop: "15px",
                }}
              />
              <input
                type="text"
                placeholder="Enter heading"
                value={heading7}
                onChange={(e) => setHeading7(e.target.value)}
                style={{
                  width: "100%",
                  marginTop: "15px",
                  padding: "10px",
                  borderRadius: "8px",
                }}
              />
              <input
                type="text"
                placeholder="Enter subheading"
                value={subheading7}
                onChange={(e) => setSubheading7(e.target.value)}
                style={{
                  width: "100%",
                  marginTop: "15px",
                  padding: "10px",
                  borderRadius: "8px",
                }}
              />

            </VuiBox>
            {/* image 3 end */}


          </VuiBox>





          {/* </VuiBox> */}
          <center>
            <Button
              onClick={handleGenerateIpadScreenshot}
              variant="outlined"
              color="white"
              sx={{
                border: "none",
                width: "75%",
                height: "50px",
                mt: 5,
                color: "white",
                borderRadius: "8px",
                fontSize: "18px",
                backgroundColor: "#1976d2",
                "&:hover": {
                  backgroundColor: "#115293",
                },
              }}
            >
              Generate Screenshots
            </Button>
          </center>
        </Card>





      </VuiBox>
      <Footer />
    </FreeLayout>
  );
}

export default Iconsize;
